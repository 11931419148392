import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'reactstrap';
import { useServices } from '@cashnu/services';
import { Dashboard, DataTable, Introduction, Purchase } from '../../components';
import { InfoContent } from './InfoContent';

import './PurchaseGroupPage.scss';

const FILTER_UNVALIDATED = 'Unvalidated';
const FILTER_APPROVED = 'Approved';
const FILTER_DECLINED = 'Declined';
const FILTER_ACTIVE = 'Active';
const FILTER_CLOSED = 'Closed';

export const Buttons = ({ filter }) => (
  <div className="button-group">
    <ButtonGroup>
      <Link to='/purchases' className={`btn btn-lg ${filter === FILTER_UNVALIDATED ? 'btn-primary' : 'btn-secondary'}`}>
        <i className="far fa-circle"></i>
        <FormattedMessage id="button.purchases" />
      </Link>
      <Link to='/purchases/approved' className={`btn btn-lg ${filter === FILTER_APPROVED ? 'btn-primary' : 'btn-secondary'}`}>
        <i className="far fa-check-circle"></i>
        <FormattedMessage id="button.approved" />
      </Link>
      <Link to='/purchases/declined' className={`btn btn-lg ${filter === FILTER_DECLINED ? 'btn-primary' : 'btn-secondary'}`}>
        <i className="far fa-times-circle"></i>
        <FormattedMessage id="button.declined" />
      </Link>
      <Link to='/purchases/active' className={`btn btn-lg ${filter === FILTER_ACTIVE ? 'btn-primary' : 'btn-secondary'}`}>
        <i className="far fa-solid fa-money-bill-wave"></i>
        <FormattedMessage id="button.active" />
      </Link>
      <Link to='/purchases/closed' className={`btn btn-lg ${filter === FILTER_CLOSED ? 'btn-primary' : 'btn-secondary'}`}>
        <i className="far fa-solid fa-lock"></i>
        <FormattedMessage id="button.closed" />
      </Link>
    </ButtonGroup>
  </div>
);

export const PurchasesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { purchaseService } = useServices();

  let filter = 0;

  switch (location.pathname) {
    default:
      filter = FILTER_UNVALIDATED;
      break;

    case '/purchases/approved':
      filter = FILTER_APPROVED;
      break;

    case '/purchases/declined':
      filter = FILTER_DECLINED;
      break;

    case '/purchases/active':
      filter = FILTER_ACTIVE;
      break;

    case '/purchases/closed':
      filter = FILTER_CLOSED;
      break;
  }

  const onClick = (purchase) => {
    navigate(`/purchase/${purchase.purchaseId}`);
  };

  const headerColumns = [
    { title: 'datum' },
    { title: 'naam' },
    { title: 'adres' },
    { title: 'verkoopbedrag' },
    { title: 'ipaddress' },
  ];

  return (
    <Dashboard className="purchase-group">
      <DataTable
        className="purchases"
        headerColumns={headerColumns}
        loadData={() => purchaseService.getPurchases(filter)}
        selectable>
        {(purchase) => <Purchase
          key={purchase.purchaseId}
          purchase={purchase}
          onClick={() => onClick(purchase)} />}
      </DataTable>
      <Introduction>
        <InfoContent />
      </Introduction>
    </Dashboard>);
}
