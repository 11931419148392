import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';

export interface LocationComponentProps {
  location: Location
}

export interface NavigateComponentProps {
  navigate: NavigateFunction
}

export interface ParamComponentProps {
  params: Readonly<Params<string>>;
}

export const withLocation = (Component: React.ComponentType<LocationComponentProps>) => (props: any) => {
  const location = useLocation();
  return <Component {...props} location={location} />;
};

export const withNavigate = (Component: React.ComponentType<NavigateComponentProps>) => (props: any) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

export const withRouteParams = (Component: React.ComponentType<ParamComponentProps>) => (props: any) => {
  const params = useParams();
  return <Component {...props} params={params} />;
};
