import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { LoadingState } from '@cashnu/services';
import { withServices } from '@cashnu/services';
import { Address, AutoCompleteAddress, Property } from '../../components';

const genderString = gender => {
  if (gender === 0) return 'Man';
  if (gender === 1) return 'Vrouw';
  if (gender === 2) return 'X';
  return gender;
}

export const identificationTypeText = (identificationType) => {
  switch (identificationType) {
    default:
      return 'Onbekend';
    case 0:
      return 'Paspoort';
    case 1:
      return 'ID kaart';
    case 2:
      return 'Rijbewijs';
  }
}

class RequestDetails extends React.Component {
  constructor(props) {
    super(props);

    this.purchaseService = props.purchaseService;
  }

  render() {
    const { purchase, loadingPurchase: loading } = this.props;

    if (loading) return <Row><Col><LoadingState /></Col></Row>;

    const { consumer, product } = purchase;

    return (
      <React.Fragment>
        <Row>
          <Col sm={6}>
            <Property label="property.product" icon="coins">{product.title}</Property>
            <Property label="property.amounts" icon="money-bill-wave">&euro; {product.purchasePrice}, &euro; {product.salesPrice}, &euro; {product.shippingCosts}</Property>
            <Property label="property.name" icon="user">{consumer.initials} {consumer.middlename} {consumer.lastname}</Property>
            <Property label="property.entered-address" icon="map-marker">
              <Address {...consumer.address} />
            </Property>
            <Property label="property.aquired-address" icon="map-marker">
              <AutoCompleteAddress {...consumer.address} />
            </Property>
            <Property label="property.birthdate" icon="birthday-cake">
              {moment(consumer.birthdate).format('LL')} <span className="age">({moment().diff(moment(consumer.birthdate), 'years')} jaar)</span>
            </Property>
          </Col>
          <Col sm={6}>
            <Property label="property.gender" icon="venus-mars">
              {genderString(consumer.gender)}
            </Property>
            <Property label="property.bankAccountNumber" icon="university">
              {consumer.bankAccountNumber}
            </Property>
            <Property label="property.ipAddress" icon="network-wired">
              <div>{purchase.ipAddress}</div>
            </Property>
            <Property label="property.email" icon="envelope">
              <div>{consumer.email}</div>
            </Property>
            <Property label="property.phoneNumber" icon="phone">
              <div>{consumer.phoneNumber}</div>
            </Property>
            <Property label="property.identification" icon="id-card">
              <div><FormattedMessage id={`identification.type.${consumer.identificationType}`} />: {consumer.identificationNumber}</div>
              <div>
                <FormattedMessage id="identification.expiration" values={{date: moment(consumer.identificationExpirationDate).format('LL')}} />
                &nbsp;
                <span className="age">({-moment().diff(moment(consumer.identificationExpirationDate), 'months')} maand(en))</span>
              </div>
            </Property>
          </Col>
        </Row>
      </React.Fragment>);
  }
}

export default withServices(RequestDetails);
