import React from 'react';
import { ValidationBox, BlankBox, INVALID, VALID } from './ValidationBox';
import { STATE_EMAIL_CONFIRMED } from '@cashnu/services';

export const EmailConfirmedBox = ({ states, loading }) => {
  if (loading) return <BlankBox />;

  const isEmailConfirmed = states.filter(state => state.state === STATE_EMAIL_CONFIRMED).length > 0;

  let footer;
  let valid;

  if (isEmailConfirmed) {
    footer = "Email is bevestigd";
    valid = VALID;
  } else {
    footer = "Email is niet bevestigd";
    valid = INVALID;
  }

  return (
    <ValidationBox
      valid={valid}
      footer={footer}
      icon="fa fa-envelope" />
  );
};
