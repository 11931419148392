import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { Address } from './Address';
import { HeaderCol } from '@cashnu/services';
import { formatMoney } from '../helpers/formatters';

import './Purchase.scss';

const traderHeader = ({ headerClass }) => (
  <Row className={headerClass || 'table-header'}>
    <HeaderCol title="datum" />
    <HeaderCol title="naam" />
    <HeaderCol title="adres" />
    <HeaderCol title="aankoopbedrag" />
    <HeaderCol title="ipaddress" />
  </Row>
);

export const PurchaseHeader = traderHeader;
export const TraderHeader = traderHeader;

export const BuyerHeader = ({ headerClass }) => (
  <Row className={headerClass || 'table-header'}>
    <HeaderCol title="datum" />
    <HeaderCol title="naam" />
    <HeaderCol title="adres" />
    <HeaderCol title="verkoopbedrag" />
    <HeaderCol title="ipaddress" />
  </Row>
);

export class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { validationCode: props.purchase.validationCode };
  }

  static getDerivedStateFromProps(props) {
    return { validationCode: props.purchase.validationCode };
  }

  render() {
    const { purchase, onClick } = this.props;
    const { ipAddress, consumer, product: { purchasePrice } } = purchase || {};

    return (
      <Row onClick={() => onClick(purchase)} className="purchase">
        <Col>
          {moment(purchase.createdAt).format('L')}
        </Col>
        <Col>
          {consumer.initials} {consumer.middlename} {consumer.lastname}
        </Col>
        <Col>
          <Address {...consumer.address} />
        </Col>
        <Col>
          &euro; {formatMoney(purchasePrice)}
        </Col>
        <Col>
          {ipAddress}
        </Col>
      </Row>
    );
  }
}
