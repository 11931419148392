import React from 'react';

export const InfoContent = () => (
  <React.Fragment>
    <h1>Aanvragen</h1>
    <hr />
    <p>
      Deze pagina toont 3 type aanvragen. De knoppen "Aanvragen", "Goedgekeurd" en "Afgekeurd" bepalen
      het type dat getoond wordt.
    </p>
    <p>
      Door op een regel in de lijst te klikken, opent een scherm met alle relevante informatie
      rondom de betreffende aanvraag.
    </p>
    <h2>Aanvragen</h2>
    <p>
      Deze lijst bevat alle nog niet gevalideerde aanvragen. Dit zijn aanvragen die
      aangemaakt zijn, nadat een consument zijn identificatie betaling heeft voldaan.
    </p>
    <h2>Goedgekeurd</h2>
    <p>
      Deze lijst bevat alle goedgekeurde aanvragen. Deze aanvragen zijn actueel en
      nog niet afgesloten.
      De aanvragen in deze lijst zijn automatisch verbonden met een inkoper en een
      aanbieder.
    </p>
    <h2>Afgekeurd</h2>
    <p>
      Deze lijst bevat alle afgekeurde aanvragen.
    </p>
  </React.Fragment>
);
