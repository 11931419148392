import React, { useEffect } from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { useAuthentication } from '@cashnu/services';
import { PurchasesPage } from '../pages/PurchasesPage';
import { PurchasePage } from '../pages/PurchasePage';
import { CommittedRequestsPage } from '../pages/RequestsPage';
import { UncommittedRequestsPage } from '../pages/RequestsPage';
import { PurchaseRequestsPage } from '../pages/RequestsPage';
import { TradersPage } from '../pages/TradersPage';
import { ConsumersPage } from '../pages/ConsumersPage';
import { TraderPage } from '../pages/TraderPage';
import { HomePage } from '../pages/HomePage';
import { ConsumerPage } from '../pages/ConsumerPage';
import { Unauthorized } from '../pages/Unauthorized';

import './Routes.scss';

const requiredRole = 'Controller';


export const PrivateRoutes = () => {
  const { isAuthenticated, roles } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) navigate('/signin');
    
    let isAuthorized = false;

    const authRoles: string[] = roles || [];

    if (!roles) {
      isAuthorized = true;
    } else {
      isAuthorized = authRoles.filter(r1 => r1 === requiredRole).length > 0;
    }
  
    if (!isAuthorized) navigate('/unauthorized');
  }, [isAuthenticated, navigate, roles]);

  return isAuthenticated ? <Outlet /> : null;
}

export const PublicRoutes = () => {
  return <Outlet />;
}

export const Dummy = () => (<div>Dummy</div>);

export const AppRoutes = () => (
  <div className="page-content">
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/requests/uncommitted" element={<UncommittedRequestsPage />} />
        <Route path="/requests/committed" element={<CommittedRequestsPage />} />
        <Route path="/requests/purchase" element={<PurchaseRequestsPage />} />
        <Route path="/requests" element={<CommittedRequestsPage />} />
        {['', 'approved','declined','active','closed'].map(path => <Route key={path} path={`/purchases/${path}`} element={<PurchasesPage />} />)}
        <Route path="/purchase/:purchaseCode" element={<PurchasePage />} />
        <Route path="/traders" element={<TradersPage />} />
        <Route path="/trader/:traderId" element={<TraderPage />} />
        <Route path="/consumers" element={<ConsumersPage />} />
        <Route path="/consumer/:consumerId" element={<ConsumerPage />} />
      </Route>
      <Route element={<PublicRoutes />}>
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Route>
    </Routes>
  </div>
);
