import React from 'react';

interface IAddress {
  street?: string;
  houseNumber?: number;
  extension?: string;
  postcode?: string;
  city?: string;
}

export const Address = ({ street, houseNumber, extension, postcode, city }: IAddress) => {
  if (!postcode && !houseNumber) return null;
  
  return (
    <div className="address">
      <div>{street ? street : (<i>{'<unknown>'}</i>)} {houseNumber} {extension}</div>
      <div>{postcode}&nbsp;&nbsp;{city || <i>{'<unknown>'}</i>}</div>
    </div>
  );
}
