import React from 'react';

import './ButtonBar.scss';

export const ButtonBar = ({ children }) => {
  return (
    <div className="button-bar">
      {children}
    </div>
  )
}