/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import Lightbox from 'react-18-image-lightbox';
import { useServices } from '@cashnu/services';
import { PdfViewer } from '../../components';

import 'react-18-image-lightbox/style.css'; // This only needs to be imported once in your app
import './Attachment.scss';

const transformDispositionIntoFilename = disposition => disposition
  .replace(/attachment\s*;/gi, "")
  .replace(/form-data\s*;/gi, "")
  .replace(/\s*name\s*=[^;]*;/gi, "")
  .replace(/\s*filename\s*=\s*/gi, "")
  .replace(/"/gi, "");

const download = (file, disposition) => {
  const filename = transformDispositionIntoFilename(disposition);

  var link = document.createElement("a");
  link.download = filename;
  link.href = file;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const translateAttachmentName = name => {
  switch (name) {
    case 'idfile':
      return 'Identificatie';
    case 'transcript1':
      return 'Bankafschrift 1';
    case 'transcript2':
      return 'Bankafschrift 2';
    case 'transcript3':
      return 'Bankafschrift 3';
    case 'Machtiging':
    case 'contract':
      return 'Het contract';
    case 'signature':
      return 'Handtekening';
    case 'Factuur':
    case 'Faktuur':
    case 'invoice':
      return 'De factuur';
    case 'Ontvangstbevestiging':
    case 'order':
      return 'Aankoop bevestiging';

    default:
      return name;
  }
}

const DownloadButton = ({ onDownload }) => (
  <button type="button" aria-label="Download" title="Download" class="ril-download ril__toolbarItemChild ril__builtinButton ril__downloadButton"
    onClick={onDownload}>
  </button>
);

const Image = ({ image, disposition, name, showLightbox, onShowLightBox, onHideLightBox }) => {
  return (
    <React.Fragment>
      <a href="/" target="_blank" title={name} rel="noreferrer" onClick={onShowLightBox}>
        <img src={image} alt={name} />
      </a>
      {showLightbox ? <Lightbox mainSrc={image} imageTitle={translateAttachmentName(name)}
        toolbarButtons={[<DownloadButton onDownload={() => download(image, disposition)} />]}
        onCloseRequest={onHideLightBox} /> : null}
    </React.Fragment>
  );
}

const PdfDocument = ({ file, disposition, name }) => {
  const [showViewer, setShowViewer] = useState(false);

  const toggle = () => setShowViewer(!showViewer);
  const filename = transformDispositionIntoFilename(disposition);

  return (
    <React.Fragment>
      {showViewer ? <PdfViewer
        url={file} title={filename}
        onDownload={() => download(file, disposition)}
        onClose={() => setShowViewer(false)} />
        : null}
      <a title={name} onClick={toggle} className="pdf-link">
        <img src="/images/pdf.png" alt={name} />
      </a>
    </React.Fragment>
  );
};

export const Attachment = ({ purchaseCode, attachment }) => {
  const { purchaseAttachmentService } = useServices();
  const [file, setFile] = useState(undefined);
  const [showLightbox, setShowLightbox] = useState(false);

  const loadAttachment = useCallback(async (purchaseCode, name) => {
    const file = await purchaseAttachmentService.getAttachment(
      purchaseCode,
      name,
    );

    setFile(file);
  }, [purchaseAttachmentService]);

  useEffect(() => {
    if (attachment) {
      loadAttachment(purchaseCode, attachment.name);
    }
  }, [purchaseCode, attachment, loadAttachment]);

  const onShowLightBox = (event) => {
    event.preventDefault();
    setShowLightbox(true);
  }

  const onHideLightBox = () => {
    setShowLightbox(false);
  }

  if (!attachment || !file) return null;

  const { name, type, disposition } = attachment;
  return (
    <div className="attachment">
      <div>{translateAttachmentName(name)}</div>
      {file && type !== 'application/pdf' ?
        <Image image={file} disposition={disposition} name={name} onHideLightBox={onHideLightBox} onShowLightBox={onShowLightBox} showLightbox={showLightbox} />
        : null}
      {file && type === 'application/pdf' ?
        <PdfDocument file={file} disposition={disposition} name={name} />
        : null}
    </div>);
}
