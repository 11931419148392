import React from 'react';
import { age } from '../../../helpers/age';
import { ValidationBox, BlankBox, INVALID, VALID, ATTENTION } from './ValidationBox';

export const AgeBox = ({ purchase, loading }) => {
  if (loading) return <BlankBox />;

  const { consumer: { birthdate } } = purchase;
  const consumerAge = age(birthdate);

  let footer;
  let valid;

  if (consumerAge < 18) {
    footer = "Jonger dan 18";
    valid = INVALID;
  } else if (consumerAge > 65) {
    footer = "Ouder dan 65";
    valid = ATTENTION;
  } else {
    footer = "Leeftijd";
    valid = VALID;
  }

  return (
    <ValidationBox
      valid={valid}
      footer={footer}
      icon="fa fa-birthday-cake" />
  );
};
