import React from 'react';
import iban from 'iban';
import { ValidationBox, BlankBox, INVALID, VALID } from './ValidationBox';

export const BankAccountNumberBox = ({ purchase, loading }) => {
  if (loading) return <BlankBox />;

  const { consumer: { bankAccountNumber } } = purchase;

  const isValidIban = iban.isValid(bankAccountNumber);

  return (
    <ValidationBox
      valid={isValidIban ? VALID : INVALID}
      footer="Geldigheid IBAN nummer"
      icon="fa fa-university" />
  );
};
