import React, { useCallback, useState } from 'react';

import './Introduction.scss';

const InfoButton = ({ onClick }) => {
  return (
    <div className="info-button" onClick={onClick}>
      <i className="fas fa-info"></i>
    </div>
  )
};

export const Introduction = ({show, children}) => {
  const [enabled, setEnabled] = useState(show);
  const [visible, setVisible] = useState(show);

  const toggleInfo = useCallback(() => {
    if (!enabled) {
      setEnabled(true);
      setTimeout(() => {
        setVisible(true);
      }, 0);
    } else {
      setVisible(false);
      setTimeout(() => {
        setEnabled(false);
      }, 400);
    }
  }, [enabled, setEnabled, setVisible]);

  const overlayClassName = `info-overlay${visible ? '' : ' hidden'}`;
  const contentClassName = `info-content${visible ? '' : ' hidden'}`;

  return (
    <React.Fragment>
      {enabled ? <div className={overlayClassName} onClick={toggleInfo}>
        <div className={contentClassName}>
          {children}
        </div>
      </div> : null}
      <InfoButton onClick={toggleInfo} />
    </React.Fragment>
  )
};
