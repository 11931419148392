import React from 'react';
import { Row, Col } from 'reactstrap';
import { useServices } from '@cashnu/services';
import { formatMoney } from '../../helpers/formatters';
import { DataTable, Dashboard, Introduction } from '../../components';
import { InfoContent } from './InfoContent';

import "./TradersPage.scss";
import { withNavigate } from '../../legacy/withNavigate';

const Trader = ({ trader, onClick }) => (
  <Row onClick={onClick}>
    <Col>
      {trader.name}
    </Col>
    <Col className="text-right">
      &euro; {formatMoney(trader.committedAmount)}
    </Col>
    <Col className="text-right">
      &euro; {formatMoney(trader.sumSalesPrices)}
    </Col>
    <Col className="text-right">
      &euro; {formatMoney(trader.sumPurchasePrices)}
    </Col>
    <Col className="text-right">
      &euro; {formatMoney(trader.sumNegativeAmounts)}
    </Col>
    <Col className="text-right">
      &euro; {formatMoney(trader.sumPositiveAmounts)}
    </Col>
    <Col>
      {trader.isBuyer ? 'Ja' : 'Nee'}
    </Col>
  </Row>
);

export const TradersPage = withNavigate(({ navigate }) => {
  const { traderService } = useServices();

  const onTraderSelect = (trader) => {
    navigate(`/trader/${trader.traderId}`);
  }

  const headerColumns = [
    { title: 'name' },
    { title: 'committed-amount' },
    { title: 'sum-sales' },
    { title: 'sum-purchases' },
    { title: 'sum-negative' },
    { title: 'sum-positive' },
    { title: 'isbuyer' },
  ];

  return (
    <Dashboard className="traders-page">
      <DataTable
        className="traders"
        headerColumns={headerColumns}
        loadData={() => traderService.list()}
        selectable>
        {(trader) => <Trader
          key={trader.traderId}
          trader={trader}
          onClick={() => onTraderSelect(trader)}
        />}
      </DataTable>

      <Introduction>
        <InfoContent />
      </Introduction>
    </Dashboard>
  );
});
