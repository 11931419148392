import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuthentication, useSettings } from '@cashnu/services';
import { NavLink } from './NavLink';

import './SideNavbar.scss';

const ListMarker = ({ className }) => <i className={className || 'fas fa-circle fa-small'}></i>;
const MenuItem = ({ children, intlId, iconClassName }) => (
  <div className="menu-item">
    <ListMarker className={iconClassName} />
    <FormattedMessage id={intlId} />
  </div>);

const RequestsMenu = (() => (
  <>
    <div className="nav-header">
      <MenuItem intlId="nav.requests" iconClassName="far fa-file-alt" />
      <NavLink to="/requests/uncommitted">
        <i className="far fa-circle"></i>
        <FormattedMessage id="nav.uncommittedrequests" />
      </NavLink>
      <NavLink to="/requests/committed">
        <i className="far fa-check-circle"></i>
        <FormattedMessage id="nav.committedrequests" />
      </NavLink>
      <NavLink to="/requests/purchase">
        <i className="far fa-shopping-cart"></i>
        <FormattedMessage id="nav.purchaserequests" />
      </NavLink>
    </div>
  </>
));

const PurchasesMenu = () => (
  <>
    <div className="nav-header">
      <MenuItem intlId="button.purchases" iconClassName="far fa-file-export" />
      <NavLink to="/purchases">
        <i className="far fa-circle"></i>
        <FormattedMessage id="button.purchases" />
      </NavLink>
      <NavLink to="/purchases/approved">
        <i className="far fa-check-circle"></i>
        <FormattedMessage id="button.approved" />
      </NavLink>
      <NavLink to="/purchases/declined">
        <i className="far fa-times-circle"></i>
        <FormattedMessage id="button.declined" />
      </NavLink>
      <NavLink to="/purchases/active">
        <i className="far fa-solid fa-money-bill-wave"></i>
        <FormattedMessage id="button.active" />
      </NavLink>
      <NavLink to="/purchases/closed">
        <i className="far fa-solid fa-lock"></i>
        <FormattedMessage id="button.closed" />
      </NavLink>
    </div>
  </>
);

export const SideNavbar = () => {
  const { oidc: { admin, authority }} = useSettings();
  const { isAuthenticated, role } = useAuthentication();

  if (!isAuthenticated) return null;

  const isController = (role || []).filter(r => r === 'Controller').length;

  return (
    <div className="side-navbar">
      <div className="nav-links">
        <NavLink to="/">
          <MenuItem intlId="nav.home" iconClassName="far fa-home" />
        </NavLink>
        {isController ?
          <React.Fragment>
            <RequestsMenu />
            <PurchasesMenu />
            <NavLink to="/traders">
              <MenuItem intlId="nav.traders" iconClassName="far fa-university" />
            </NavLink>
            <NavLink to="/consumers">
              <MenuItem intlId="nav.consumers" iconClassName="far fa-users" />
            </NavLink>
          </React.Fragment> : null}
      </div>
      <div>
      </div>
      <div className="nav-links">
        <NavLink href={admin || authority} target="_blank">
          <MenuItem intlId="nav.admin" iconClassName="far fa-shield" />
        </NavLink>
        <NavLink to="/signout">
          <MenuItem intlId="nav.signout" iconClassName="far fa-sign-out" />
        </NavLink>
      </div>
    </div>
  );
};
