import React from 'react';
import { FormattedMessage } from 'react-intl';

import './Property.scss';

export const Property = ({ label, icon, children }) => (
  <div className="property">
    <i className={`fa fa-${icon} mr-2`} aria-hidden="true"></i>
    <div>
      <div className="property-label">
        <FormattedMessage id={label} defaultMessage={label} />:
      </div>
      <div className="property-content">
        {children}
      </div>
    </div>
  </div>
);
