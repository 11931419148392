import React from 'react';
import { RequestsPage } from './RequestsPage';
import { FILTER_UNCOMMITTED_REQUESTS } from './RequestFilterType';

export const UncommittedRequestsPage = () => {
  const loadRequests = async (requestService, listFilter, listOptions) => await requestService.uncommitted(listFilter, listOptions);

  return (
    <RequestsPage
      filter={FILTER_UNCOMMITTED_REQUESTS}
      buttons={{ consumerButton: true, paymentButton: false, mailButton: false }}
      loadRequests={loadRequests}
      paymentStatus={false}
    />
  );
};