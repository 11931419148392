import React from 'react';
import { RequestsPage } from './RequestsPage';
import { FILTER_COMMITTED_REQUESTS } from './RequestFilterType';

export const CommittedRequestsPage = () => {
  const loadRequests = async (requestService, listFilter, listOptions) => await requestService.committed(listFilter, listOptions);

  return (
    <RequestsPage
      filter={FILTER_COMMITTED_REQUESTS}
      buttons={{ consumerButton: true, paymentButton: true, mailButton: true }}
      loadRequests={loadRequests}
      paymentStatus={true}
    />
  );
};
