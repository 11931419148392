import React from 'react';
import { ValidationBox, BlankBox, INVALID, VALID, ATTENTION } from './ValidationBox';

export const RelatedPurchasesBox = ({ related, loading }) => {
  if (loading) return <BlankBox />;

  let footer;
  let valid;

  if (related.length === 0) {
    footer = "Geen overeenkomstige aanvragen";
    valid = VALID;
  } else if (related === 1) {
    footer = "1 overeenkomstige aanvraag";
    valid = ATTENTION;
  } else {
    footer = "Meerdere overeenkomstige aanvragen";
    valid = INVALID;
  }

  return (
    <ValidationBox
      valid={valid}
      footer={footer}
      icon="fa fa-equals" />
  );
};
