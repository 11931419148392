import React from 'react';
import moment from 'moment';
import { ValidationBox, BlankBox, INVALID, VALID, ATTENTION } from './ValidationBox';

export const IdentificationExpirationBox = ({ purchase, loading }) => {
  if (loading) return <BlankBox />;

  const { consumer: { identificationExpirationDate } } = purchase;

  const isLicenseExpirationValid = moment(identificationExpirationDate) > moment(purchase.createdAt).add(3, 'month');
  const isLicenseExpirationValidToday = moment(identificationExpirationDate) > moment().add(3, 'month');

  let footer;

  if (isLicenseExpirationValidToday) {
    footer = "Identificatie is niet verlopen";
  } else if (isLicenseExpirationValid) {
    footer = "Identificatie was niet verlopen bij invoer";
  } else {
    footer = "Identificatie is verlopen";
  }
  return (
    <ValidationBox
      valid={isLicenseExpirationValidToday ? VALID : isLicenseExpirationValid ? ATTENTION : INVALID}
      footer={footer}
      icon="fa fa-id-card" />
  );
};
