import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { UserTag } from './UserTag';
import { ModeSwitch } from '../ModeSwitch';
import { MustBeAuthenticated } from '../MustBeAuthenticated';

import { version } from '../../../package.json';

import './TopNavbar.scss';

export const TopNavbar = (props) => (
  <div className="top-navbar">
    <div className="logo">
      <div className="logo-block">
        <img src="/images/logo.svg" title="CashNu" alt="CashNu" />
        <div className="version">{version}</div>
      </div>
      <InfoText />
    </div>
    <MustBeAuthenticated>
      <div className="user-profile">
        <ModeSwitch />
        <UserTag />
      </div>
    </MustBeAuthenticated>
  </div>
);

const GetInfoText = (pathname) => {
  switch (pathname) {
    default: {
      if (/\/consumer\/\d+/g.test(pathname)) {
        return {
          header: 'nav.consumer',
          info: 'Consument gegevens.'
        };
      }
      if (/\/trader\/\d+/g.test(pathname)) {
        return {
          header: 'nav.trader',
          info: 'Aanbieder gegevens.'
        };
      }
      return null;
    }

    case '/requests/uncommitted':
      return {
        header: 'nav.uncommittedrequests',
        info: 'Ingevulde formulieren. Wachtend op documenten upload.'
      };

    case '/requests/committed':
      return {
        header: 'nav.committedrequests',
        info: 'Documenten geupload. Wachtend op identificatie betaling.'
      };

    case '/requests/purchase':
      return {
        header: 'nav.purchaserequests',
        info: 'Dit zijn munt-aankoop aanvragen. Zodra de betaling heeft plaats gevonden, dient de munt verstuurd te worden.'
      };

    case '/purchases':
      return {
        header: 'button.purchases',
        info: 'Onbehandelde aanvragen, die wachten op beoordeling. Identificatie betaling heeft plaats gevonden.'
      };

    case '/purchases/approved':
      return {
        header: 'button.approved',
        info: 'Deze aanvragen wachten op uitbetaling aan consument.'
      };

    case '/purchases/declined':
      return {
        header: 'button.declined',
        info: 'Deze aanvragen zijn afgekeurd.'
      };

    case '/purchases/active':
      return {
        header: 'button.active',
        info: 'Deze aanvragen wachten op betaling van consument.'
      };

    case '/purchases/closed':
      return {
        header: 'button.closed',
        info: 'Deze aanvragen zijn afgesloten.'
      };
  }
}

const InfoText = () => {
  const { pathname } = useLocation();
  const [infoContent, setInfoContent] = useState(() => null);
  const [className, setClassName] = useState('hidden');

  useEffect(() => {
    const content = GetInfoText(pathname);
    setClassName('hidden');
    if (content) {
      const timer = setTimeout(() => {
        setInfoContent(content);
        setClassName('');
      }, 50);
      return () => { clearTimeout(timer); };
    }
  }, [pathname]);

  return (
    <div className="info-text-container">
      <div className={`info-text ${className}`}>
        {infoContent ?
          <>
            <b><FormattedMessage id={infoContent.header} /></b>
            <div>{infoContent.info}</div>
          </> : null}
      </div>
    </div>
  );
}
