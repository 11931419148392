import React from 'react';
import { Row, Col } from 'reactstrap';
import { LoadingState } from '@cashnu/services';
import { Property } from '../../components';
import { formatMoney } from '../../helpers/formatters';

export const TraderDetails = ({ trader, loadingTrader: loading }) => {
  if (loading) return <Row><Col><LoadingState /></Col></Row>;
  if (!trader) return null;

  return (
    <React.Fragment>
      <div className="side-by-side">
        <div>
          <Property label="property.name" icon="user">{trader.name}</Property>
        </div><div>
          <Property label="property.committedAmount" icon="money-bill-wave">
            &euro; {formatMoney(trader.committedAmount)}
          </Property>
        </div>
      </div>
    </React.Fragment>);
}
