import React from 'react';
import {
  EmailConfirmedBox,
  AgeBox,
  RelatedPurchasesBox,
  BankAccountNumberBox,
  IdentificationNumberBox,
  IdentificationExpirationBox,
  SignedContractBox,
  //BlankBox
} from './Validations';

const RequestStatuses = ({ purchase, states, related, loadingPurchase, loadingRelated, loadingStates }) => {
  return (
    <div className="validations counter-bar">
      <EmailConfirmedBox states={states} loading={loadingStates} />
      <AgeBox purchase={purchase} loading={loadingPurchase} />
      <RelatedPurchasesBox related={related} loading={loadingRelated} />
      <BankAccountNumberBox purchase={purchase} loading={loadingPurchase} />
      <IdentificationNumberBox purchase={purchase} loading={loadingPurchase} />
      <IdentificationExpirationBox purchase={purchase} loading={loadingPurchase} />
      <SignedContractBox states={states} loading={loadingPurchase} />
    </div>
  );
};

export default RequestStatuses;
