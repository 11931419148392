import { IChildren } from '@cashnu/services';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Switch from 'react-switch';

import './ModeSwitch.scss';

interface IModeContext {
  mode: string;
  setMode: (mode: string) => void;
}

const LOCALSTORAGENAME = 'ui-modus';
export const UIMODE_LIGHT = 'light';
export const UIMODE_DARK = 'dark';

const ModeContext = React.createContext<IModeContext>({
  mode: UIMODE_LIGHT,
  setMode: (mode: string) => {},
});

export const ModeConsumer = ModeContext.Consumer;

interface IModeProviderProps extends IChildren {
}

export const ModeProvider = ({ children }: IModeProviderProps): ReactElement => {
  const [mode, setMode] = useState<string>(UIMODE_LIGHT);

  useEffect(() => {
    const mode = localStorage.getItem(LOCALSTORAGENAME);
    if (mode === UIMODE_DARK || mode === UIMODE_LIGHT) {
      setMode(mode);
    }
  }, []);

  const value = useMemo<IModeContext>(() => ({
    mode,
    setMode: (newMode: string): void => {
      localStorage.setItem(LOCALSTORAGENAME, newMode);
      setMode(newMode);
    },
  }), [mode]);

  return (
    <ModeContext.Provider value={value}>
      {children}
    </ModeContext.Provider>
  );
}

export const useMode = () => useContext(ModeContext);

export const ModeSwitch = () => {
  const { mode, setMode } = useMode();

  return (
    <div className="mode-switch">
      <Switch
        checked={mode === UIMODE_DARK}
        onChange={() => setMode(mode === UIMODE_DARK ? UIMODE_LIGHT : UIMODE_DARK)}
        onColor="#005c8d"
        offColor="#005c8d"
        uncheckedHandleIcon={<i className="fas fa-lightbulb-on"></i>}
        checkedHandleIcon={<i className="fas fa-moon" aria-hidden="true"></i>}
        uncheckedIcon={false}
        checkedIcon={false}
        height={24}
        width={50}
      />
      <span className="label">
        <FormattedMessage id={ mode === UIMODE_DARK ? 'mode-switch-dark' : 'mode-switch-light' } />
      </span>
    </div>
  );
}
