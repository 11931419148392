import React from 'react';
import { withServices } from '@cashnu/services';
import { LoadingState } from '@cashnu/services';
import { Page, Section } from '../../components';
import { TraderDetails } from './TraderDetails';
import { TraderTransactions } from './TraderTransactions';
import { TraderPurchases } from './TraderPurchases';
import BuyerPurchases from './BuyerPurchases';
import { withRouteParams } from '../../legacy/withNavigate';

import "./TraderPage.scss";

class InternalTraderPage extends React.Component {
  constructor(props) {
    super(props);

    this.traderService = props.traderService;

    this.state = {
      traderId: props.params.traderId,
      trader: {},
      loadingTrader: true,
    };
  }

  async loadTrader() {
    const { traderId } = this.state;

    const trader = await this.traderService.get(traderId);
    this.setState({ trader, loadingTrader: false });
  }

  componentDidMount() {
    this.loadTrader();
  }

  render() {
    const {
      trader,
      loadingTrader,
    } = this.state;

    if (loadingTrader) return <LoadingState />;

    return (
      <Page className="trader-page">
        <Section title="section.details">
          <TraderDetails trader={trader} loadingTrader={loadingTrader} />
        </Section>
        <Section title="section.transactions">
          {({ isZoomed }) => <TraderTransactions trader={trader} isZoomed={isZoomed} />}
        </Section>
        <Section title="section.bound-sales">
          <TraderPurchases trader={trader} />
        </Section>
        <Section title="section.bound-purchases">
          <BuyerPurchases buyer={trader} />
        </Section>
      </Page>
    );
  }
}

export const TraderPage = withRouteParams(withServices(InternalTraderPage));
