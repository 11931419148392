import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColProps } from 'reactstrap';

interface IHeaderColProps {
  title: string;
}

export const HeaderCol = ({ title, ...colProps }: IHeaderColProps & ColProps) => {
  return (
    <Col {...colProps}>
      <FormattedMessage id={`columnheader.${title}`} />
    </Col>
  )
};
