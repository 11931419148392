import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import {
  withServices,
  withConfirm
} from '@cashnu/services';
import {
  STATE_DOCUMENTS_SIGNED,
  STATE_EMAIL_CONFIRMED,
  STATE_VALIDATED,
} from '@cashnu/services';
import RequestDetails from './RequestDetails';
import RequestStatuses from './RequestStatuses';
import RequestRelated from './RequestRelated';
import { RequestTrader } from './RequestTrader';
import { RequestBuyer } from './RequestBuyer';
import RequestAttachments from './RequestAttachments';
import RequestValidations from './RequestValidations';
import { ContractUploadModal } from './ContractUploadModal';
import { Page, Section } from '../../components';
import { withNotifications } from '../../helpers/notifications';
import { withLoader } from './Loader';
import { withLocation, withRouteParams } from '../../legacy/withNavigate';

import './PurchasePage.scss';

class InternalPurchasePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      purchaseCode: props.params.purchaseCode,
      purchase: {},
      loadingPurchase: true,
      states: [],
      loadingStates: true,
      related: [],
      loadingRelated: true,
      refreshingStates: false,
      updatingStatuses: false,
    };

    this.onApprove = this.onApprove.bind(this);
    this.onDecline = this.onDecline.bind(this);
    this.onUploadedContract = this.onUploadedContract.bind(this);
    this.onSendDocumentsMail = this.onSendDocumentsMail.bind(this);
  }

  static getDerivedStateFromProps(props) {
    return {
      purchase: props.purchase,
      loadingPurchase: props.loadingPurchase,
      states: props.states,
      loadingStates: props.loadingStates,
      related: props.related,
      loadingRelated: props.loadingRelated,
    };
  }

  async onApprove() {
    const { confirm, loadPurchase, loadStates, notificationManager, services } = this.props;
    const { purchaseService } = services;

    const answer = await confirm({ title: 'header.approvePurchase', question: 'question.approvePurchase' });
    if (answer !== true) return;

    const { purchaseCode } = this.state;
    try {
      await purchaseService.approve(purchaseCode);
      this.setState({ modalConfirmApprove: false });
      notificationManager.success('purchase.approved');
      loadPurchase();
      loadStates();
    } catch (e) {
      notificationManager.error(e.message);
      console.error(e.message);
    }
  }

  async onDecline() {
    const { confirm, loadPurchase, loadStates, notificationManager, services } = this.props;
    const { purchaseService } = services;

    const answer = await confirm({ title: 'header.declinePurchase', question: 'question.declinePurchase' });
    if (answer !== true) return;

    const { purchaseCode } = this.state;
    try {
      await purchaseService.decline(purchaseCode);
      this.setState({ modalConfirmDecline: false });
      notificationManager.warning('purchase.disapproved');
      loadPurchase();
      loadStates();
    } catch (e) {
      notificationManager.error(e.message);
      console.error(e.message);
    }
  }

  onUploadedContract() {
    const { loadPurchase, loadStates } = this.props;
    loadPurchase();
    loadStates();
  }

  async onSendDocumentsMail() {
    const { confirm } = this.props;
    const { purchase } = this.state;

    const answer = await confirm({ title: 'header.sendDocumentsMail', question: 'question.sendDocumentsMail', questionValues: { email: purchase.consumer.email } });
    if (answer !== true) return;

    const { notificationManager, purchaseService } = this.props;
    const { purchaseCode } = this.state;
    await purchaseService.sendDocumentsMail(purchaseCode);
    notificationManager.success('purchase.documentMailSent');
  }

  render() {
    const { loadPurchase, loadStates } = this.props;
    const {
      purchaseCode, updatingStatuses,
      purchase, loadingPurchase,
      states, loadingStates, refreshingStates,
      related, loadingRelated,
      isUploadContractOpen
    } = this.state;

    const isValidated = (purchase && purchase.state >= STATE_VALIDATED);
    const isEmailConfirmed = (purchase && purchase.state >= STATE_EMAIL_CONFIRMED);
    const isContractSigned = (purchase && purchase.state >= STATE_DOCUMENTS_SIGNED);

    const openContractUploadOpen = () => this.setState({ isUploadContractOpen: true });
    const closeContractUploadOpen = () => this.setState({ isUploadContractOpen: false });

    const Buttons = () => (
      <ButtonGroup>
        <Button disabled={updatingStatuses || isValidated || isContractSigned || !isEmailConfirmed} color={isEmailConfirmed && !isValidated && !isContractSigned ? 'primary' : 'secondary'}
          onClick={this.onSendDocumentsMail}>
          <i className="far fa-envelope"></i>
          <FormattedMessage id="button.sendDocumentsMail" />
        </Button>
        <Button disabled={updatingStatuses || isValidated || isContractSigned || !isEmailConfirmed} color={isEmailConfirmed && !isValidated && !isContractSigned ? 'primary' : 'secondary'}
          onClick={openContractUploadOpen}>
          <i className="far fa-file-contract"></i>
          <FormattedMessage id="button.contract" />
        </Button>
        <Button disabled={updatingStatuses || isValidated || !isContractSigned || !isEmailConfirmed} color={isEmailConfirmed && isContractSigned ? 'success' : 'secondary'}
          onClick={this.onApprove}>
          <i className="far fa-thumbs-up"></i>
          <FormattedMessage id="button.approve" />
        </Button>
        <Button disabled={updatingStatuses || isValidated}
          onClick={this.onDecline}
          color={!isValidated ? 'danger' : 'secondary'} >
          <i className="far fa-thumbs-down"></i>
          <FormattedMessage id="button.decline" />
        </Button>
      </ButtonGroup>);

    const StateRefreshButton = () => (
      <div
        className={`refresh-button ${refreshingStates ? 'disabled' : ''}`}
        onClick={loadStates}
        title="Refresh">
        <i className="fa fa-sync-alt"></i>
      </div>
    );

    return (
      <Page className="purchase-page">
        <ContractUploadModal
          purchaseCode={purchaseCode}
          isOpen={isUploadContractOpen}
          close={closeContractUploadOpen}
          onUploaded={this.onUploadedContract} />

        <Buttons />
        <React.Fragment>
          {!isValidated && purchase && !loadingStates && !loadingPurchase ?
            <RequestValidations
              purchaseCode={purchaseCode}
              purchase={purchase} states={states} related={related}
              loadingPurchase={loadingPurchase} loadingStates={loadingStates} loadingRelated={loadingRelated} />
            : null}
          <Section title="section.details">
            {purchase ? <RequestDetails purchase={purchase} loadingPurchase={loadingPurchase} /> : null}
          </Section>
          {purchase && (purchase.trader || purchase.buyer) ?
            <div className="side-by-side">
              {purchase.trader
                ? <RequestTrader purchase={purchase} loadPurchase={loadPurchase} loadStates={loadStates} />
                : null}
              {purchase.buyer
                ? <RequestBuyer purchase={purchase} loadPurchase={loadPurchase} loadStates={loadStates} />
                : null}
            </div>
            : null}
          <Section title="section.attachments" initialState={isValidated}>
            <RequestAttachments purchaseCode={purchaseCode} />
          </Section>
          <Section title="section.status" initialState={true} buttons={<StateRefreshButton />}>
            <RequestStatuses purchaseCode={purchaseCode} states={states} loading={loadingStates} />
          </Section>
          <Section title="section.related" initialState={isValidated}>
            <RequestRelated purchaseCode={purchaseCode} related={related} loading={loadingRelated} />
          </Section>
        </React.Fragment>
      </Page>
    );
  }
}

export const PurchasePage =
  withNotifications(
    withConfirm(
      withRouteParams(
        withLocation(
          withLoader(withServices(InternalPurchasePage))))));
