import React from 'react';

export const InfoContent = () => (
  <React.Fragment>
    <h1>Formulieren</h1>
    <hr />
    <p>
      Formulieren zijn ingevuld of worden ingevuld door de consument. Deze Formulieren
      worden omgezet naar "aanvragen", zodra de consument de identificatie betaling van &euro; 1,-
      heeft voldaan.
    </p>
    <p>
      Als de identificatie betaling is voldaan, zal het systeem deze formulieren omzetten tot
      aanvraag en zal het formulier verwijderd worden.
    </p>
    <p>
      Bij ieder formulier staan 3 knoppen (rechts). Deze knoppen zijn:
      <i className="mx-1 far fa-sync-alt" title="Betaalstatus verversen"></i>
      <i className="mx-1 far fa-window-maximize" title="Open consumenten pagina"></i>
      <i className="mx-1 far fa-envelope" title="Verstuur email opnieuw"></i>
    </p>
    <h2><i className="far fa-sync-alt" title="Betaalstatus verversen"></i> Betaalstatus verversen</h2>
    <p>
      Deze haalt de actuele status op van "alle" betalingen die de consument gedaan of geprobeerd heeft met betrekking
      tot dit formulier. De actuele betaalstatus wordt weergegeven in de lijst.
    </p>
    <h2><i className="far fa-window-maximize" title="Open consumenten pagina"></i> Open consumenten pagina</h2>
    <p>Deze knop opent de consumenten website met de gegevens van het betreffende formulier.</p>
    <h2><i className="far fa-envelope" title="Verstuur email opnieuw"></i> Verstuur email opnieuw</h2>
    <p>Zodra de consument klaar is met het invoeren van gegevens en het uploaden van documenten, ontvangt
      de consument een email met een link naar de identificatie betaling pagina. Met deze knop kan deze mail
      opnieuw verstuurd worden.
    </p>
  </React.Fragment>
);
