import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useServices } from '@cashnu/services';
import { Address } from './Address';

import './AutoCompleteAddress.scss';

export const AutoCompleteAddress = ({ houseNumber, extension, postcode }) => {
  const [address, setAddress] = useState(undefined);
  const { addressService } = useServices();

  useEffect(() => {
    async function fetch() {
      const address = await addressService.find(postcode, houseNumber);
      setAddress(address);
    }
    fetch();
  }, [addressService, postcode, houseNumber]);

  if (!address) {
    return (
      <div className="address-container">
        <FormattedMessage id="no.address" />
      </div>
    );
  }
  
  return (
    <div className="address-container">
      <Address {...(address || {})} />
      <a href={`https://maps.google.com?q=${address.latitude},${address.longitude}`} target="_blank" rel="noreferrer">
        <FormattedMessage id="open-in-google-maps" />
      </a>
    </div>);
};
