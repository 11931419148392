import React from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IntlProvider } from 'react-intl';
import { ReactNotifications, Store } from 'react-notifications-component'

import {
  AddressService,
  Compose,
  ConsumerService,
  PurchaseAttachmentService,
  PurchaseService,
  PurchaseStateService,
  RequestService,
  StatisticsService,
  TraderService,
  TransactionService,
  AuthenticationRoutes,
  AuthenticationProvider,
  ConfirmProvider,
  ErrorBoundaryProvider,
  ServicesProvider,
  SettingsProvider,
  IAuthenticationSettings,
  useAuthentication,
  useSettings,
  RequestAttachmentService,
} from '@cashnu/services';
import { AppRoutes } from './routes';
import { SideNavbar } from './components/layout/SideNavbar';
import { TopNavbar } from './components/layout/TopNavbar';
import { useMode, ModeProvider } from './components/ModeSwitch';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-notifications/lib/notifications.css';
import './App.scss';
import 'moment/locale/nl';

import messagesNl from "./lang/nl.json";
import { version } from '../package.json';
import { IAppServices } from './components/IAppServices';
import { useErrorContext } from './contexts';

moment.locale('nl');

export const Version = () => (
  <div className="version-info">{version}</div>
);

interface IServiceSettings {
  apiUrl: string;
}

// const configureServices = (
//   settings: IServiceSettings,
//   userManager: Oidc.UserManager,
//   errorCallback: CallBackFunction,
// ) => ({
//   requestService: new RequestService(settings, userManager, errorCallback),
//   purchaseService: new PurchaseService(settings, userManager, errorCallback),
//   purchaseAttachmentService: new PurchaseAttachmentService(settings, userManager, errorCallback),
//   purchaseStateService: new PurchaseStateService(settings, userManager, errorCallback),
//   consumerService: new ConsumerService(settings, userManager, errorCallback),
//   addressService: new AddressService(settings, userManager, errorCallback),
//   statisticsService: new StatisticsService(settings, userManager, errorCallback),
//   traderService: new TraderService(settings, userManager, errorCallback),
//   transactionService: new TransactionService(settings, userManager, errorCallback),
// });

const SettingsLoader = () => {
  return (
    <div className="settings-page loader-page">
      <div className="loader-content">
        Loading settings ...
      </div>
    </div>
  );
}

const useErorReport = () => {
  const navigate = useNavigate();
  const errorContext = useErrorContext();

  return (e: Error): never => {
    navigate('/error');
    errorContext.setError(e);
    
    Store.addNotification({
      title: 'Error',
      message: e.message,
      type: 'danger',
      insert: "top",
      container: "top-right",
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 5000,
        click: true,
        pauseOnHover: true,
        showIcon: true,
        onScreen: true
      }
    });
    throw e;
  }
}

interface IAppSettings extends IAuthenticationSettings, IServiceSettings {
}

const contexts = [
  <BrowserRouter />,
  <SettingsProvider<IAppSettings> filename='/settings.json' loadingComponent={<SettingsLoader />} />,
  <IntlProvider locale="nl" messages={messagesNl} />,
  <AuthenticationProvider alwaysGuest />,
  <ErrorBoundaryProvider />,
  <ConfirmProvider />,
  <ModeProvider />,
];

const Main = () => {
  const { mode } = useMode();
  const { userManager } = useAuthentication();
  const settings = useSettings<IAppSettings>();
  const errorReport = useErorReport();

  if (userManager === undefined) throw new Error('Missing user manager');
  
  const configureServices = (
  ): IAppServices => ({
    requestService: new RequestService({ settings, userManager, errorReport }),
    requestAttachmentService: new RequestAttachmentService({ settings, userManager, errorReport }),
    purchaseService: new PurchaseService({ settings, userManager, errorReport }),
    purchaseAttachmentService: new PurchaseAttachmentService({ settings, userManager, errorReport }),
    purchaseStateService: new PurchaseStateService({ settings, userManager, errorReport }),
    consumerService: new ConsumerService({ settings, userManager, errorReport }),
    addressService: new AddressService({ settings, userManager, errorReport }),
    statisticsService: new StatisticsService({ settings, userManager, errorReport }),
    traderService: new TraderService({ settings, userManager, errorReport }),
    transactionService: new TransactionService({ settings, userManager, errorReport }),
  });

  return (
    <ErrorBoundaryProvider>
      <ServicesProvider configureServices={configureServices}>
        <div className={`main-dashboard ${mode}`}>
          <div className="topbar">
            <TopNavbar />
          </div>
          <div className="content-section">
            <div className="sidebar">
              <SideNavbar />
            </div>
            <div className="main-content">
              <AppRoutes />
            </div>
          </div>
        </div>
      </ServicesProvider>
    </ErrorBoundaryProvider>
  );
}

const App = () => (
  <Compose components={contexts}>
    <ReactNotifications />
    <Routes>
      <Route path="*" element={<AuthenticationRoutes />} />
      <Route path="*" element={<Main />} />
    </Routes>
    <Main />
  </Compose>
);

export default App;
