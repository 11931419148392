import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices, withErrorBoundary } from '@cashnu/services';

const Loader = withErrorBoundary(({ WrappedComponent, ...props}) => {
  const { purchaseService, purchaseStateService } = useServices();
  const { purchaseCode } = useParams();
  const [purchaseSet, setPurchaseSet] = useState({ purchase: {}, loadingPurchase: true });
  const [statesSet, setStatesSet] = useState({ states: {}, loadingStates: true });
  const [relatedSet, setRelatedSet] = useState({ related: [], loadingRelated: true });

  const loadPurchase = useCallback(async () => {
    try {
      setPurchaseSet({ loadingPurchase: true });
      const loadedPurchase = await purchaseService.get(purchaseCode);
      setPurchaseSet({ purchase: loadedPurchase, loadingPurchase: false });
    } catch (e) {
      // throwError(e);
      console.error(e);
    }
  }, [setPurchaseSet, purchaseService, purchaseCode]);

  const loadStates = useCallback(async () => {
    try {
      setStatesSet({ states: undefined, loadingStates: true });
      const loadedStates = await purchaseStateService.getStates(purchaseCode);
      setStatesSet({ states: loadedStates, loadingStates: false });
    } catch (e) {
      // throwError(e);
      console.error(e);
    }
  }, [setStatesSet, purchaseStateService, purchaseCode]);

  const loadRelated = useCallback(async () => {
    try {
      setRelatedSet({ related: undefined, loadingRelated: true });
      const loadedStates = await purchaseService.getRelated(purchaseCode);
      setRelatedSet({ related: loadedStates, loadingRelated: false });
    } catch (e) {
      // throwError(e);
      console.error(e);
    }
  }, [setRelatedSet, purchaseService, purchaseCode]);

  useEffect(() => {
    loadPurchase();
    loadStates();
    loadRelated();
  }, [loadPurchase, loadStates, loadRelated]);

  return <WrappedComponent
    {...props}
    loadPurchase={loadPurchase}
    {...purchaseSet}
    loadStates={loadStates}
    {...statesSet}
    loadRelated={loadRelated}
    {...relatedSet}
  />
});

export const withLoader = (WrappedComponent) =>
  (props) => <Loader WrappedComponent={WrappedComponent} {...props} />;
