import React from 'react';
import { Container } from 'reactstrap';

import './Page.scss';

export const PageComponent = ({ children, className }) => (
  <div className={`page ${className}`}>
    {children}
  </div>
);

export const Page = ({ children, className }) => (
  <Container>
    <PageComponent className={`page ${className}`}>
      {children}
    </PageComponent>
  </Container>
);

export const Dashboard = ({ children, className }) => (
  <PageComponent className={`dashboard ${className}`}>
    {children}
  </PageComponent>
);
