import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import './PdfViewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const PdfViewer = ({ url, title, onDownload, onDelete, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onLoadError = e => console.error(e);

  const preventClick = e => {
    e.preventDefault();
    e.stopPropagation();
  }

  useEffect(() => {
    setPageNumber(1);
  }, [setPageNumber]);

  return (
    <div className="pdf-viewer-container">
      <div className="pdf-modal-background" onClick={onClose}>
        <div className="pdf-viewer-header" onClick={preventClick}>
          <div className="pdf-title">{title}</div>
          <div className="pdf-buttons">
            {onDownload ? <div title="Download" onClick={onDownload}><i className="fas fa-download"></i></div> : null}
            {onDelete ? <div title="Verwijderen" onClick={onDelete}><i className="fas fa-trash"></i></div> : null}
            <button type="button" aria-label="Close lightbox" title="Close lightbox" class="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton" onClick={onClose}></button>
          </div>
        </div>
        <div className="pdf-viewer-content">
          <Document file={{ url }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onLoadError}>
            <Page pageNumber={pageNumber} width="600" />
          </Document>
          <div className="pdf-viewer-footer">Page {pageNumber} of {numPages}</div>
        </div>
      </div>
    </div>);
};

