import React from 'react';

export const InfoContent = () => (
  <React.Fragment>
    <h1>Aanbieder / Inkopers</h1>
    <hr />
    <p>
      In deze lijst worden alle actieve aanbieders getoond. In de laatste kolom 'Inkoper' wordt aangegeven of Deze
      aanbieder ook inkoper is.
    </p>
    <h2>Gecommiteerd bedrag</h2>
    <p>
      Dit is het bedrag waar de inkoper maximaal voor wil inkopen. Zodra dit bedrag bereikt door aankopen, zonder dat 
      dit door verkoop gecompenseerd is, zal deze niet meer automatisch als inkoper worden toegekend aan een aanvraag.
    </p>
    <h2>Totaal verkoop</h2>
    <p>
      Dit bedrag is het totaalbedrag van alle verkopen
    </p>
    <h2>Totaal inkoop</h2>
    <p>
      Dit bedrag is het totaalbedrag van alle inkopen
    </p>
  </React.Fragment>
);
