import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LoadingState,
  STATE_VALIDATED,
  STATE_AMOUNT_PAYED_TO_CONSUMER,
  useConfirm,
  useServices,
} from '@cashnu/services';
import { Property, Section } from '../../components';
import { formatMoney } from '../../helpers/formatters';
import { withNotifications } from '../../helpers/notifications';

const RequestBuyerContent = ({ buyer, purchase }) => (
  <React.Fragment>
    <Property label="property.name" icon="user">
      <Link to={`/trader/${buyer.traderId}`}>{buyer.name}</Link>
    </Property>
    <Property label="property.amountToPayToConsumer" icon="money-bill-wave">
      &euro; {formatMoney(purchase.product.purchasePrice)}
    </Property>
    <Property label="property.amountPayedToConsumer" icon="money-bill-wave">
      &euro; {formatMoney(purchase.state >= STATE_AMOUNT_PAYED_TO_CONSUMER ? purchase.product.purchasePrice : 0)}
    </Property>
  </React.Fragment>
);

const BuyerPayButton = withNotifications(({ purchase, loadPurchase, loadStates, notificationManager }) => {
  const intl = useIntl();
  const { confirm } = useConfirm();
  const { traderService } = useServices();

  const payedToConsumer = async () => {
    const { purchaseId: purchaseCode, product: { purchasePrice } } = purchase;

    const answer = await confirm({
      title: 'registerTransaction',
      question: 'question.registerTransaction',
      questionValues: {
        amount: <b>&euro; {formatMoney(purchasePrice)}</b>,
        from: <b><FormattedMessage id="the buyer" /></b>,
        to: <b><FormattedMessage id="the consumer" /></b>
      }
    });

    if (answer !== true) return;

    try {
      await traderService.payedToConsumer(purchaseCode);
      notificationManager.success("Transactie is geregistreerd");
      loadPurchase();
      loadStates();
    } catch (e) {
      if (/Purchase already payed/gi.test(e.message))
        notificationManager.error('trader.payment.alreadyRegistered');
      else
        notificationManager.error(e.message);
      console.error(e.message);
    }
  };

  return (
    <div
      className={`pay-button ${purchase.buyerPaidConsumer ? 'disabled' : ''}`}
      onClick={purchase.buyerPaidConsumer ? () => { } : payedToConsumer}
      title={intl.formatMessage({ id: 'pay-consumer' })}>
      <i className="fas fa-money-bill-wave"></i>
    </div>);
});

export const RequestBuyer = ({ purchase: { buyer }, purchase, loadPurchase, loadStates }) => {
  const buttons = purchase.state >= STATE_VALIDATED && purchase.state < STATE_AMOUNT_PAYED_TO_CONSUMER
    ? <BuyerPayButton purchase={purchase} loadPurchase={loadPurchase} loadStates={loadStates} />
    : null;

  const content = (buyer && purchase)
    ? <RequestBuyerContent buyer={buyer} purchase={purchase} />
    : <LoadingState />;

  return (
    <Section title="section.buyer" buttons={buttons}>
      {content}
    </Section>);
};
