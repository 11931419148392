import React from 'react';
import { Container } from 'reactstrap';
import { withServices } from '@cashnu/services';
import { LoadingState } from '@cashnu/services';
import { Purchase, BuyerHeader } from '../../components';
import { withNavigate } from '../../legacy/withNavigate';

class BuyerPurchases extends React.Component {
  constructor(props) {
    super(props);

    this.purchaseService = props.purchaseService;

    this.state = {
      purchases: [],
      loadingPurchases: true,
    }

    this.onClick = this.onClick.bind(this);
  }

  async loadTransaction() {
    const { buyer: { traderId } } = this.props;
    var purchases = (await this.purchaseService.getByBuyer(traderId)) || [];
    this.setState({ purchases, loadingPurchases: false });
  }

  componentDidMount() {
    this.loadTransaction();
  }

  onClick(purchase) {
    const { navigate } = this.props;
    navigate(`/request/${purchase.purchaseId}`);
  }

  render() {
    const { purchases, loadingPurchases: loading } = this.state;

    return (
      <Container>
        <div className="requests data-table">
          <BuyerHeader />
          {loading ? <LoadingState /> :
            (purchases && purchases.length ? purchases.map(request =>
              <Purchase key={request.purchaseId}
                purchase={request}
                onClick={() => this.onClick(request)}
              />) : <div>Geen aanvragen beschikbaar</div>)}
        </div>
      </Container>);
  }
}

export default withNavigate(withServices(BuyerPurchases));
