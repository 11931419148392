import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { Address } from './Address';
import { HeaderCol } from '@cashnu/services';
import { withConfirm, useServices } from '@cashnu/services';
import { useSettings } from '@cashnu/services';
import { formatMoney } from '../helpers/formatters';
import { withNotifications } from '../helpers/notifications';

import './Request.scss';

export const RequestHeader = ({ headerClass, paymentStatus, buttons }) => (
  <Row className={headerClass || 'table-header'}>
    <HeaderCol title="datum" />
    <HeaderCol title="naam" />
    <HeaderCol title="adres" />
    <HeaderCol title="aankoopbedrag" />
    <HeaderCol title="email" />
    <HeaderCol title="ipaddress" />
    <Col></Col>
  </Row>
);

export const InternalRequest = ({ request: initialRequest, notificationManager, buttons, confirm }) => {
  const { requestService } = useServices();
  const [request, setRequest] = useState(initialRequest);
  const settings = useSettings();
  
  if (!buttons) buttons = { consumerButton: true, paymentButton: false, mailButton: false };

  const updatePaymentStatus = async () => {
    try {
      const updatedRequest = await requestService.updatePaymentStatus(request.requestId);
      notificationManager.info('paymentStatus.updated');
      setRequest(updatedRequest);
    } catch (e) {
      notificationManager.error(e.message);
    }
  }

  const openConsumerPage = () => {
    let url;

    if (request.type === 2) {
      url = `${settings.consumerUrl}/purchase/${request.requestId}`;
    } else {
      url = `${settings.consumerUrl}/request/form/${request.requestId}`;
    }
    window.open(url, '_blank');
  }

  const resendMail = async () => {
    const answer = await confirm({
      title: 'header.resendMail',
      question: 'question.resendMail',
      questionValues: { email: <b>{request.email}</b> },
    });

    if (answer !== true) return;

    try {
      await requestService.resendMail(request.requestId);
      notificationManager.info('email.resent', 'Email is resent to {email}', { email: request.email });
    } catch (e) {
      notificationManager.error(e.message);
    }
  }

  const { ipAddress, product: { purchasePrice } } = request || {};

  return (
    <Row className="request">
      <Col>
        {moment(request.createdAt).format('L')}
      </Col>
      <Col>
        {request.initials} {request.middlename} {request.lastname}
      </Col>
      <Col>
        <Address {...request.address} />
      </Col>
      <Col className="text-right">
        &euro; {formatMoney(purchasePrice)}
      </Col>
      <Col>
        {request.email}
      </Col>
      <Col>
        {ipAddress}
      </Col>
      <Col>
        <div className="buttons">
          { buttons.paymentButton ? <i onClick={updatePaymentStatus} className="far fa-sync-alt" title="Betaalstatus verversen"></i> : null }
          { buttons.consumerButton ? <i onClick={openConsumerPage} className="far fa-window-maximize" title="Open consumenten pagina"></i> : null }
          { buttons.mailButton ? <i onClick={resendMail} className="far fa-envelope" title="Verstuur email opnieuw"></i> : null }
        </div>
      </Col>
    </Row>
  );
}

export const Request = withConfirm(withNotifications(InternalRequest));
