import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '@cashnu/services';

interface IMustBeAuthenticatedProps {
  children: any;
}
export const MustBeAuthenticated = ({ children }: IMustBeAuthenticatedProps) => {
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) return null;

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
};

export const mustHaveRole = (Component: React.ComponentType, role: string) => {
  return (props: any) => {
    const { isAuthenticated, roles } = useAuthentication();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isAuthenticated) navigate('/signin');
      
      let isAuthorized = false;

      const authRoles: string[] = roles || [];

      if (role) {
        if (!roles) {
          isAuthorized = true;
        } else if (typeof role === 'string') {
          isAuthorized = authRoles.filter(r1 => r1 === role).length > 0;
        }
      }
    
      if (!isAuthorized) navigate('/unauthorized');
    }, [isAuthenticated, navigate, roles]);

    return <Component {...props} />;
  }
};