import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useErrorBoundary } from '@cashnu/services';
import { LoadingState } from '@cashnu/services';

interface IHeaderColumn {
  title: string;
}

interface IHeaderColumnProps extends IHeaderColumn {
}

const HeaderColumn = ({ title }: IHeaderColumnProps): ReactElement => (
  <Col className="header-column">
    {title}
  </Col>
);

interface IDataTableHeaderProps {
  headerColumns: IHeaderColumn[];
}

const DataTableHeader = ({ headerColumns }: IDataTableHeaderProps): ReactElement | null => {
  if (!headerColumns || !headerColumns.length) return null;

  return (
    <div className="row header">
      {headerColumns.map(headerColumn => <HeaderColumn title={headerColumn.title} key={headerColumn.title} />)}
    </div>);
};

type Children = ((options: { setElementRef(el: HTMLElement): any } ) => ReactNode) | undefined;

interface IDataTableRowsProps {
  loading: boolean;
  rows: [];
  error?: Error;
  children: Children;
}

const DataTableRows = ({ loading, rows, error, children }: IDataTableRowsProps)
  : ReactElement => {

  if (!children) throw new Error('DataTableRows must have children');

  if (loading || error) {
    return (
      <Row className="data-loading">
        <Col>
          <LoadingState title='Loading' error={error!} />
        </Col>
      </Row>);
  }

  if (!rows || !rows.length) {
    return (
      <Row className="data-unavailable">
        <Col>
          <div>Geen aanvragen beschikbaar</div>
        </Col>
      </Row>
    );
  }

  return <>{rows.map(row => children(row))}</>;
};

interface IDataTableProps {
  className?: string;
  headerColumns: IHeaderColumn[];
  loadData: () => [];
  selectable: boolean;
  children: Children;
}

export const DataTable = ({ className, headerColumns, loadData, children, selectable }: IDataTableProps)
: ReactElement => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<[]>([]);
  const [error, setError] = useState<Error>();
  const { throwError } = useErrorBoundary();

  useEffect(() => {
    const loader = async () => {
      try {
        const data = await loadData();
        setRows(data);
      }
      catch (e) {
        setError(e as Error);
        // throwError(e);
      }
      finally {
        setLoading(false);
      }
    };

    loader();
  }, [loadData, throwError]);

  return (
    <div className={`${className} compact-list data-table ${selectable ? 'row-selectable' : ''}`}>
      <DataTableHeader headerColumns={headerColumns} />

      <DataTableRows loading={loading} rows={rows} error={error}>
        {children}
      </DataTableRows>
    </div>
  )
}
