import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useServices } from '@cashnu/services';
import { LoadingState } from '@cashnu/services';
import { Purchase, TraderHeader } from '../../components';
import { withNavigate } from '../../legacy/withNavigate';

export const TraderPurchases = withNavigate(({ navigate, trader: { traderId } }) => {
  const { purchaseService } = useServices();
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);

  const onClick = (purchase) => {
    navigate(`/request/${purchase.purchaseId}`);
  }

  useEffect(() => {
    const loadTransaction = async () => {
      var purchases = (await purchaseService.getByTrader(traderId)) || [];
      setPurchases(purchases);
      setLoading(false);
    }
  
    loadTransaction();
  }, [purchaseService, traderId]);

  return (
    <Container>
      <div className="requests data-table">
        <TraderHeader />
        {loading ? <LoadingState /> :
          (purchases && purchases.length ? purchases.map(request =>
            <Purchase key={request.purchaseId}
              purchase={request}
              onClick={() => onClick(request)}
            />) : <div>Geen aanvragen beschikbaar</div>)}
      </div>
    </Container>);
});
