import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useServices } from '@cashnu/services';
import { FormProvider, useFormData } from '@cashnu/forms';
import { withNotifications } from '../../helpers/notifications';
import Dropzone from 'react-dropzone';

const FileField = ({ id, children, disabled }) => {
  const { set } = useFormData(id);

  const onDrop = (files) => {
    if (files.length === 1) set(id, files, true);
  }

  return (
    <div>
      <Dropzone
        maxFiles={1}
        multiple={false}
        onDrop={onDrop}
        accept='image/jpeg, image/png, application/pdf'
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: classNames('dropzone', { 'dropzone-disabled': disabled }) })}>
            <input {...getInputProps()} />
            {children}
          </div>)}
      </Dropzone>
    </div>);
}

export const ContractUploadModal = withNotifications(({ notificationManager, purchaseCode, isOpen, close, onUploaded }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const { purchaseAttachmentService } = useServices();

  const onSubmit = (data) => {
    try {
      purchaseAttachmentService
        .saveAttachment(purchaseCode, 'contract', data.contract[0])
        .then(() => {
          notificationManager.success('contract-saved');
          close();
          onUploaded();
        }, (e) => { console.error(e); })
        .catch(e => { console.error(e); });
    } catch (e) {
      console.error(e);
    }
  }

  const onCanSubmit = (value) => {
    console.log('onCanSubmit', value);
    setCanSubmit(value);
  }

  return (
    <Modal isOpen={isOpen} toggle={close} size='lg'>
      <ModalHeader>
        <FormattedMessage id="header.upload-contract" />
      </ModalHeader>
      <FormProvider data={{}}
        onSubmit={onSubmit}
        onEnableSubmit={onCanSubmit}>
        <ModalBody>
          <div className="col">
            Voeg hier het ondertekende contract toe. Zowel een foto of een scan is toegestaan, mits deze duidelijk leesbaar is.
          </div>
          <div className="files">
            <FileField id="contract" maxFiles={1}>
              Klik hier om het ondertekend contract te selecteren of sleep het document naar dit vlak.
            </FileField>
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button type="submit" disabled={!canSubmit} color={canSubmit ? 'primary' : 'secondary'}>
              <FormattedMessage id="button.save" />
            </Button>
            <Button onClick={() => close()}>
              <FormattedMessage id="button.cancel" />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </FormProvider>
    </Modal >
  );
});