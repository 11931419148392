import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Container, Row, Col, Button } from 'reactstrap';
import { LoadingState } from '@cashnu/services';
import { useServices } from '@cashnu/services';
import { HeaderCol } from '../../components';
import { formatMoney } from '../../helpers/formatters';
import { ButtonBar } from '../../components/ButtonBar';

const Transactions = ({ transactions }) => {
  return (
    <React.Fragment>
      <Row className="table-header">
        <HeaderCol sm={2} title="id" />
        <HeaderCol sm={2} title="consumer" />
        <HeaderCol sm={2} title="amount" />
        <HeaderCol sm={6} title="description" />
      </Row>
      {
        transactions && transactions.length ? transactions.map(transaction => (
          <Row key={transaction.transactionId}>
            <Col sm={2}>{moment(transaction.createdAt).format('L')}</Col>
            <Col sm={2}>{transaction.consumerName}</Col>
            <Col sm={2} className="text-right">&euro; {formatMoney(transaction.traderAmount)}</Col>
            <Col sm={6}>{transaction.description}</Col>
          </Row>
        )) : <Row><Col>Geen transacties</Col></Row>
      }
      {
        transactions && transactions.length ? (
          <Row className="table-footer">
            <Col sm={2}></Col>
            <Col sm={2}></Col>
            <Col sm={2} className="text-right">
              &euro; {formatMoney(transactions.reduce((a, b) => a + b.traderAmount, 0))}
            </Col>
            <Col sm={6}></Col>
          </Row>
        ) : null
      }
    </React.Fragment>);
};

export const TraderTransactions = ({ isZoomed, trader: { traderId } }) => {
  const { transactionService } = useServices();
  const [transactions, setTransactions] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const loadTransaction = useCallback(async () => {
    const transactions = await transactionService.getByTrader(traderId);
    setTransactions(transactions);
    setLoading(false);
  }, [transactionService, setTransactions, setLoading, traderId]);

  useEffect(() => {
    loadTransaction();
  }, [loadTransaction]);

  if (loading) return <Row><Col><LoadingState /></Col></Row>;

  if (isZoomed) {
    return (
      <React.Fragment>
        <ButtonBar>
          <Button title="Print"><i className="fa fa-print" /></Button>
          <Button title="Mail"><i className="fa fa-envelope" /></Button>
        </ButtonBar>
        <Container>
          <Transactions transactions={transactions} />
        </Container>
      </React.Fragment>);
  } else {
    return (
      <Container>
        <Transactions transactions={transactions} />
      </Container>);
    };
}
