import React from 'react';
import { useServices } from '@cashnu/services';
import { Dashboard, DataTable, Introduction, Request } from '../../components';
import { InfoContent } from './InfoContent';

import './RequestsPage.scss';

export const RequestsPage = ({ onClick, buttons, loadRequests, paymentStatus }) => {
  const { requestService } = useServices();

  const headerColumns = [
    { title: 'datum' },
    { title: 'naam' },
    { title: 'adres' },
    { title: 'aankoopbedrag' },
    { title: 'email' },
    { title: 'ipaddress' },
    { title: '' }
  ];

  return (
    <Dashboard className="requests-page">
      <DataTable
        className="requests"
        headerColumns={headerColumns}
        loadData={() => loadRequests(requestService)}
      >
        {(request) => <Request
          key={request.requestId}
          buttons={buttons}
          paymentButton
          paymentStatus={paymentStatus}
          request={request}
          onClick={() => onClick(request)}
        />}
      </DataTable>

      <Introduction>
        <InfoContent />
      </Introduction>
    </Dashboard>
  );
}
