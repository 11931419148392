import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { useServices, useErrorBoundary } from '@cashnu/services';
import { mustHaveRole, Page } from '../../components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import "./HomePage.scss";
import { withNavigate } from '../../legacy/withNavigate';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Counter = withNavigate(({ navigate, title, count, color, icon, footer, path, history }) => {
  let className = 'counter';
  if (color) className += ` counter-${color}`;
  if (path) className += ` clickable`;

  return (
    <div className={className} onClick={path ? () => navigate(path) : null}>
      <div className='counter-body'>
        {icon ? <div className={`icon icon-${icon}`}><i className={`fa fa-${icon}`}></i></div> : null}
        <div className="title"><FormattedMessage id={title} /></div>
        <div className="number">{count}</div>
        <div className="spacer"></div>
        {footer ? <div className="footer">{footer}</div> : null}
      </div>
    </div>);
});

const Tags = ({ statistics }) => {
  if (!statistics) return null;

  return (
    <div className='counter-bar'>
      <Counter color="blue" count={statistics.numberOfUnvalidatedPurchases} title="counter.open-purchases" icon="folder-open"
        path="/purchases" />
      <Counter color="orange" count={statistics.numberOfApprovedPurchases} title="counter.approved-purchases" icon="check"
        path="/purchases/approved" />
      <Counter color="red" count={statistics.numberOfDeclinedPurchases} title="counter.declined-purchases" icon="times"
        path="/purchases/declined" />
      <Counter color="green" count={statistics.numberOfActivePurchases} title="counter.active-purchases" icon="chart-line"
        path="/purchases/active" />
      <Counter color="grey" count={statistics.numberOfClosedPurchases} title="counter.closed-purchases" icon="clipboard-check"
        path="/purchases/closed" />
    </div>);
}

const colors = ['157,68,157', '67,160,71', '224,174,24', '0,92,141', '251,140,0', '229,57,53', '192,192,192'];

const date = (date) => {
  var x = moment(date, 'DD-MM-YYYY');
  return x;
}

const day = (date) => {
  var x = moment(date, 'DD-MM-YYYY');
  return x.date();
}

const dayAndMonth = (date) => {
  var x = moment(date, 'DD-MM-YYYY');
  return `${x.date()} ${x.format('MMMM')}`;
}

const getChartOptions = (title) => ({
    responsive: true,
    scales: {
      y: {
        min: 0,
        afterBuildTicks: (axis) => {
          // Remove ticks with decimals.
          axis.ticks = axis.ticks.filter(t => t.value - Math.floor(t.value) === 0);
          return;
        },
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    },
});

const WeekChart = ({ weekGraph }) => {
  if (!weekGraph) return null;

  const options = getChartOptions('Week overzicht');
  const labels = weekGraph ? weekGraph.map(d => dayAndMonth(d.key)) : [];

  const data = {
    labels,
    datasets: weekGraph ? [
      {
        label: 'Bezoeken',
        data: weekGraph.map(d => d.visits),
        borderColor: `rgb(${colors[0]})`,
        backgroundColor: `rgba(${colors[0]}, 0.5)`,
      },
      {
        label: 'Formulieren',
        data: () => weekGraph.map(d => d.requests),
        borderColor: `rgb(${colors[1]})`,
        backgroundColor: `rgba(${colors[1]}, 0.5)`,
      },
      {
        label: 'Munt aankopen',
        data: weekGraph.map(d => d.coinPurchases),
        borderColor: `rgb(${colors[2]})`,
        backgroundColor: `rgba(${colors[2]}, 0.5)`,
      },
      {
        label: 'Aanvragen',
        data: weekGraph.map(d => d.purchases),
        borderColor: `rgb(${colors[3]})`,
        backgroundColor: `rgba(${colors[3]}, 0.5)`,
      },
      {
        label: 'Goedgekeurd',
        data: weekGraph.map(d => d.approved),
        borderColor: `rgb(${colors[4]})`,
        backgroundColor: `rgba(${colors[4]}, 0.5)`,
      },
      {
        label: 'Afgekeurd',
        data: weekGraph.map(d => d.declined),
        borderColor: `rgb(${colors[5]})`,
        backgroundColor: `rgba(${colors[5]}, 0.5)`,
      },
      {
        label: 'Gesloten',
        data: weekGraph.map(d => d.closed),
        borderColor: `rgb(${colors[6]})`,
        backgroundColor: `rgba(${colors[6]}, 0.5)`,
      },
    ] : [],
  };

  return (
    <div className='chart'>
      <Line options={options} data={data} height="110" />
    </div>);
};

const MonthChartRequests = ({ monthGraph }) => {
  if (!monthGraph) return null;

  const now = date(monthGraph[0].key);
  const options = getChartOptions(`${now.format('MMMM')} ${now.format('YYYY')}`);
  const labels = monthGraph ? monthGraph.map(d => day(d.key)) : [];

  const data = {
    labels,
    datasets: monthGraph ? [
      {
        label: 'Bezoeken',
        data: monthGraph.map(d => d.visits),
        borderColor: `rgb(${colors[0]})`,
        backgroundColor: `rgba(${colors[0]}, 0.5)`,
      },
      {
        label: 'Formulieren',
        data: () => monthGraph.map(d => d.requests),
        borderColor: `rgb(${colors[1]})`,
        backgroundColor: `rgba(${colors[1]}, 0.5)`,
      },
      {
        label: 'Munt aankopen',
        data: monthGraph.map(d => d.coinPurchases),
        borderColor: `rgb(${colors[2]})`,
        backgroundColor: `rgba(${colors[2]}, 0.5)`,
      },
    ] : [],
  };

  return (
    <div className='chart'>
      <Line options={options} data={data} />
    </div>);
};


const MonthChartPurchases = ({ monthGraph }) => {
  if (!monthGraph) return null;

  const now = date(monthGraph[0].key);
  const options = getChartOptions(`${now.format('MMMM')} ${now.format('YYYY')}`);
  const labels = monthGraph ? monthGraph.map(d => day(d.key)) : [];

  const data = {
    labels,
    datasets: monthGraph ? [
      {
        label: 'Aanvragen',
        data: monthGraph.map(d => d.purchases),
        borderColor: `rgb(${colors[3]})`,
        backgroundColor: `rgba(${colors[3]}, 0.5)`,
      },
      {
        label: 'Goedgekeurd',
        data: monthGraph.map(d => d.approved),
        borderColor: `rgb(${colors[4]})`,
        backgroundColor: `rgba(${colors[4]}, 0.5)`,
      },
      {
        label: 'Afgekeurd',
        data: monthGraph.map(d => d.declined),
        borderColor: `rgb(${colors[5]})`,
        backgroundColor: `rgba(${colors[5]}, 0.5)`,
      },
      {
        label: 'Gesloten',
        data: monthGraph.map(d => d.closed),
        borderColor: `rgb(${colors[6]})`,
        backgroundColor: `rgba(${colors[6]}, 0.5)`,
      },
    ] : [],
  };

  return (
    <div className='chart'>
      <Line options={options} data={data} />
    </div>);
};

export const HomePage = mustHaveRole(() => {
  const { purchaseService, statisticsService } = useServices();
  const { throwError } = useErrorBoundary();
  const [statistics, setStatistics] = useState(undefined);
  const [thisWeekGraph, setThisWeekGraph] = useState(undefined);
  const [thisMonthGraph, setThisMonthGraph] = useState(undefined);
  const [lastMonthGraph, setLastMonthGraph] = useState(undefined);

  useEffect(() => {
    purchaseService.getStatistics()
      .then(
        data => setStatistics(data),
        error => throwError(error));

    statisticsService.thisWeek()
      .then(
        data => setThisWeekGraph(data),
        error => throwError(error));

    statisticsService.thisMonth()
      .then(
        data => setThisMonthGraph(data),
        error => throwError(error));

    statisticsService.lastMonth()
      .then(
        data => setLastMonthGraph(data),
        error => throwError(error));
    }, [purchaseService, statisticsService, throwError]);

  return (
    <Page className="home-page">
      <Tags statistics={statistics} />
      <WeekChart weekGraph={thisWeekGraph} />
      <br/>
      <Row>
        <Col sm={6}>
          <MonthChartRequests monthGraph={thisMonthGraph} />
        </Col>
        <Col sm={6}>
          <MonthChartPurchases monthGraph={thisMonthGraph} />
        </Col>
      </Row>
      <br/>
      <Row>
        <Col sm={6}>
          <MonthChartRequests monthGraph={lastMonthGraph} />
        </Col>
        <Col sm={6}>
          <MonthChartPurchases monthGraph={lastMonthGraph} />
        </Col>
      </Row>
    </Page>
  );
}, 'Controller');
