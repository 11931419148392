import React from 'react';
import { Page } from '../../components';

export const Unauthorized = () => (
  <Page>
    <div className="text-center mt-5">
      <p>Dit account heeft onvoldoende rechten om van deze applicatie gebruik te maken.</p>
      <p>Meld af en meld u opnieuw aan met een ander account.</p>
    </div>
  </Page>);
