import React from 'react';
import Gravatar from 'react-gravatar';
import { useAuthentication, useSettings } from '@cashnu/services';

import './UserTag.scss';

export const UserTag = () => {
  const { isAuthenticated, profile } = useAuthentication();
  const { oidc: { authority } } = useSettings();

  if (!isAuthenticated || !profile) return null;

  return (
    <a className="account-name" href={authority} target="_blank" rel="noreferrer">
      {profile.email ? <Gravatar email={profile.email} size={48} rating="pg" className="gravatar" default="mp" /> : null}
      <div>
        <div className="profile-name">{profile.name}</div>
        {profile.email ? <div className="profile-email">{profile.email}</div> : null}
      </div>
    </a>);
};
