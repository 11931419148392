import React from 'react';
import { STATE_DOCUMENTS_SIGNED } from '@cashnu/services';
import { INVALID, VALID } from '.';
import { ValidationBox, BlankBox } from './ValidationBox';

export const SignedContractBox = ({ states, loading }) => {
  if (loading) return <BlankBox />;

  const isContractSigned = states.filter(state => state.state === STATE_DOCUMENTS_SIGNED).length > 0;

  let footer;
  let valid;

  if (isContractSigned) {
    footer = 'Ondertekend contract';
    valid = VALID;
  } else {
    footer = 'Geen ondertekend contract';
    valid = INVALID;
  }

  return (
    <ValidationBox
      valid={valid}
      footer={footer}
      icon="fa fa-file-contract" />
  );
};
