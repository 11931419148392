import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { LoadingState } from '@cashnu/services';
import { HeaderCol } from '../../components';

const RequestStatuses = ({ states, loading }) => {
  if (loading) return <Row><Col><LoadingState /></Col></Row>;

  return (
    <React.Fragment>
      <Row className="table-header">
        <HeaderCol sm={2} title="when" />
        <HeaderCol sm={1} title="by" />
        <HeaderCol sm={2} title="category" />
        <HeaderCol sm={3} title="state" />
        <HeaderCol title="value" />
        <HeaderCol title="ipaddress" />
      </Row>
      {states.map((state, index) =>
        <Row key={`${state.statusChangedAt}${index}`} className="small-content">
          <Col sm={2}>
            <span>{moment(state.statusChangedAt).format('L')}</span>
            &nbsp;
            <span>{moment(state.statusChangedAt).format('HH:mm')}</span>
          </Col>
          <Col sm={1}>{state.statusChangedBy || (<i>system</i>)}</Col>
          <Col sm={2}>{state.category}</Col>
          <Col sm={3}>{state.status}</Col>
          <Col>{state.amount ? <React.Fragment>&euro; {state.amount.toFixed(2)}</React.Fragment> : state.value}</Col>
          <Col>{state.ipAddress}</Col>
        </Row>)}
    </React.Fragment>
  );
};

export default RequestStatuses;
