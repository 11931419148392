import React from 'react';
import { validateDriversLicenseNumber, validatePassportNumber } from '@cashnu/services';
import { injectIntl } from 'react-intl';
import { ValidationBox, BlankBox, INVALID, VALID } from './ValidationBox';

export const IdentificationNumberBox = injectIntl(({ purchase, intl, loading }) => {
  if (loading) return <BlankBox />;

  const { consumer: { identificationType, identificationNumber } } = purchase;

  const isValidIdentificationNumber =
    identificationType !== 2
      ? validatePassportNumber(identificationNumber)
      : validateDriversLicenseNumber(identificationNumber);

  const idType = intl.formatMessage({ id: `identification.type.${identificationType}` });
  const footer = intl.formatMessage({
    id: isValidIdentificationNumber ? 'identification.numberValid' : 'identification.numberInvalid'
  }, { identificationType: idType });
  return (
    <ValidationBox
      valid={isValidIdentificationNumber ? VALID : INVALID}
      footer={footer}
      icon="fa fa-id-card" />
  );
});
