import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './NavLink.scss';

export const NavLink = ({ children, className, to, href, target, staticContext, ...rest }) => {
  const { pathname } = useLocation();

  const isActive = pathname === to;
  const linkClassName = `${(className || 'nav-link')} ${(isActive ? 'active' : '')}`;

  if (!to && href) {
    return (
      <a className={linkClassName} href={href} target={target}>
        {children}
      </a>
    );
  }
  return (
    <Link className={linkClassName} to={to} {...rest}>
      {children}
    </Link>
  );
}
