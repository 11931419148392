import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { LoadingState, withServices } from '@cashnu/services';
import { Address, HeaderCol } from '../../components';
import { formatMoney } from '../../helpers/formatters';

const Match = ({ match }) => {
  switch (match) {
    default:
      return null;

    case 'email':
      return <i className="fa fa-envelope" title="Overeenkomstig email address"></i>;
    case 'phoneNumber':
      return <i className="fa fa-phone" title="Overeenkomstig telefoonnummer"></i>;
    case 'addressId':
      return <i className="fa fa-map-marker" title="Overeenkomstig adres"></i>;
    case 'bankAccountNumber':
      return <i className="fa fa-university" title="Overeenkomstig bankrekeningnummer"></i>;
    case 'ipAddress':
      return <i className="fa fa-network-wired" title="Overeenkomstig IP adres"></i>;
    case 'lastname':
      return <i className="fa fa-user" title="Overeenkomstige achternaam"></i>;
  }
}

class RequestRelated extends React.Component {
  constructor(props) {
    super(props);

    this.purchaseService = props.purchaseService;

    this.state = {
      purchaseCode: props.purchaseCode,
      related: props.related || [],
      loading: props.loading,
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      purchaseCode: props.purchaseCode,
      related: props.related || [],
      loading: props.loading,
    };
  }

  render() {
    const { related, loading } = this.state;

    if (loading) return <Row><Col><LoadingState /></Col></Row>;

    if (related.length === 0) return <Row><Col>Geen gerelateerde aanvragen</Col></Row>;
    
    return (
      <div className="related-requests data-table">
        <Row className="table-header">
          <HeaderCol sm={2} />
          <HeaderCol sm={2} title="date" />
          <HeaderCol title="purchaseprice" />
          <HeaderCol sm={2} title="name" />
          <HeaderCol sm={2} title="address" />
          <HeaderCol sm={3} />
        </Row>
        {related.map(({ matches, purchase, purchase: { consumer, product } }, index) =>
          <Row key={purchase.purchaseId + index}>
            <Col sm={2} className="buttons">
              {purchase.validationCode === 1 ? <i className="valid far fa-check-circle" title="Goedgekeurde aanvraag"></i> : null}
              {purchase.validationCode === 2 ? <i className="invalid far fa-times-circle" title="Afgekeurde aanvraag"></i> : null}
              {matches.map(m => <Match key={m} match={m} />)}
            </Col>
            <Col sm={2}>{moment(purchase.createdAt).format('LLL')}</Col>
            <Col>&euro; {formatMoney(product.purchasePrice)}</Col>
            <Col sm={2}>{consumer.initials} {consumer.middlename} {consumer.lastname}</Col>
            <Col sm={2}><Address {...purchase.consumer.address} /></Col>
            <Col sm={3}>
              <div><i className="fa fa-envelope"></i><a href={`mailto:${purchase.consumer.email}`}>{purchase.consumer.email}</a></div>
              <div><i className="fa fa-network-wired"></i>{purchase.ipAddress}</div>
              <div><i className="fa fa-university"></i>{purchase.consumer.bankAccountNumber}</div>
            </Col>
          </Row>)}
      </div>
    );
  }
}

export default withServices(RequestRelated);
