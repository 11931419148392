import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { LoadingState, useServices } from '@cashnu/services';
import { withServices } from '@cashnu/services';
import { Attachment } from './Attachment';

const RequestAttachments = ({ purchaseCode }) => {
  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState();
  const { purchaseAttachmentService } = useServices();

  const loadAttachments = useCallback(async () => {
    const attachments = await purchaseAttachmentService.getAttachments(purchaseCode);
    setAttachments(attachments);
    setLoading(false);
  }, [purchaseAttachmentService, purchaseCode]);

  useEffect(() => {
    loadAttachments();
  }, [loadAttachments]);

  if (loading) return <Row><Col><LoadingState /></Col></Row>;
  if (!attachments) return <Row><Col>Geen attachments</Col></Row>

  return (
    <div className="attachments">
      {attachments.map((attachment, index) => (
        <div key={attachment.name}>
          <Attachment purchaseCode={purchaseCode} attachment={attachment} />
        </div>
      ))}
    </div>
  );
}

export default withServices(RequestAttachments);
