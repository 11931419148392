import { NotificationManager as InnerNotificationManager } from 'react-notifications';
import { injectIntl } from 'react-intl';

export const injectNotificationManager = function (intl) {
  const success = (id, defaultMessage, values) => InnerNotificationManager.success(intl.formatMessage({ id, defaultMessage }, values));
  const error = (id, defaultMessage, values) => InnerNotificationManager.error(intl.formatMessage({ id, defaultMessage }, values));
  const info = (id, defaultMessage, values) => InnerNotificationManager.info(intl.formatMessage({ id, defaultMessage }, values));
  const warning = (id, defaultMessage, values) => InnerNotificationManager.warning(intl.formatMessage({ id, defaultMessage }, values));
  return {
    success,
    error,
    info,
    warning,
  };
};

export function withNotifications(WrappedComponent) 
{
  return injectIntl(
    ({ intl, ...props }) => <WrappedComponent notificationManager={injectNotificationManager(intl)} {...props}  />);
}
