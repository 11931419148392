import React from 'react';
import { RequestsPage } from './RequestsPage';
import { FILTER_PURCHASE_REQUESTS } from './RequestFilterType';

export const PurchaseRequestsPage = () => {
  const loadRequests = async (requestService, listFilter, listOptions) => await requestService.purchases(listFilter, listOptions);

  return (
    <RequestsPage
      filter={FILTER_PURCHASE_REQUESTS}
      buttons={{ consumerButton: true, paymentButton: true, mailButton: false }}
      loadRequests={loadRequests}
      paymentStatus={true}
    />
  );
};
