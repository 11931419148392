import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Container } from 'reactstrap';
import { LoadingState } from '@cashnu/services';
import { withServices, withErrorBoundary } from '@cashnu/services';
import { Address, Property, Section } from '../../components';

import "./ConsumerPage.scss";

const genderString = gender => {
  if (gender === 0) return 'Man';
  if (gender === 1) return 'Vrouw';
  if (gender === 2) return 'X';
  return gender;
}

class InternalConsumerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      consumer: [],
      loading: true
    };
  }

  async loadConsumer() {
    const { match: { params: { consumerId }}, consumerService, throwError } = this.props;
    try {
      const consumer = await consumerService.get(consumerId);
      this.setState({ consumer, loading: false });
    } catch(e) {
      throwError(e);
    }
  }

  componentDidMount() {
    this.loadConsumer();
  }

  render() {
    const { consumer, loading } = this.state;

    return (
      <div className="consumer-page">
        <Container>
          {loading ? <LoadingState /> :
          <Section title="section.details">
        <Row>
          <Col sm={6}>
            <Property label="property.name" icon="user">{consumer.initials} {consumer.middlename} {consumer.lastname}</Property>
            <Property label="property.entered-address" icon="map-marker">
              <Address {...consumer.address} />
            </Property>
            <Property label="property.birthdate" icon="birthday-cake">
              {moment(consumer.birthdate).format('LL')} <span className="age">({moment().diff(moment(consumer.birthdate), 'years')} jaar)</span>
            </Property>
            <Property label="property.gender" icon="venus-mars">
              {genderString(consumer.gender)}
            </Property>
          </Col>
          <Col sm={6}>
            <Property label="property.bankAccountNumber" icon="university">
              {consumer.bankAccountNumber}
            </Property>
            <Property label="property.email" icon="envelope">
              <div>{consumer.email}</div>
            </Property>
            <Property label="property.phoneNumber" icon="phone">
              <div>{consumer.phoneNumber}</div>
            </Property>
            <Property label="property.identification" icon="id-card">
              <div><FormattedMessage id={`identification.type.${consumer.identificationType}`} />: {consumer.identificationNumber}</div>
              <div>
                <FormattedMessage id="identification.expiration" values={{date: moment(consumer.identificationExpirationDate).format('LL')}} />
                &nbsp;
                <span className="age">({-moment().diff(moment(consumer.identificationExpirationDate), 'months')} maand(en))</span>
              </div>
            </Property>
          </Col>
        </Row>
          </Section>}
          <Section title="section.purchases">

          </Section>
        </Container>
      </div>
    );
  }
};

export const ConsumerPage = withErrorBoundary(withServices(InternalConsumerPage));
