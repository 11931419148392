import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LoadingState,
  STATE_AMOUNT_PAYED_TO_CONSUMER,
  STATE_AMOUNT_RECEIVED_FROM_CONSUMER,
  useConfirm,
  useServices,
} from '@cashnu/services';
import { Property, Section } from '../../components';
import { formatMoney } from '../../helpers/formatters';
import { withNotifications } from '../../helpers/notifications';

const RequestTraderContent = ({ trader, purchase}) => (
  <React.Fragment>
    <Property label="property.name" icon="user">
      <Link to={`/trader/${trader.traderId}`}>{trader.name}</Link>
    </Property>
    <Property label="property.amountToPayByConsumer" icon="money-bill-wave">
      &euro; {formatMoney(purchase.product.salesPrice)}
    </Property>
    <Property label="property.amountPayedByConsumer" icon="money-bill-wave">
      &euro; {formatMoney(purchase.state >= STATE_AMOUNT_RECEIVED_FROM_CONSUMER ? purchase.product.salesPrice : 0)}
    </Property>
  </React.Fragment>
);

const TraderPaidButton = withNotifications(({ purchase, loadPurchase, loadStates, notificationManager }) => {
  const intl = useIntl();
  const { confirm } = useConfirm();
  const { traderService } = useServices();

  const payedByConsumer = async () => {
    const { purchaseId: purchaseCode, product: { salesPrice }} = purchase;

    const answer = await confirm({
      title: 'registerTransaction',
      question: 'question.registerTransaction',
      questionValues: {
        amount: formatMoney(salesPrice),
        from: <b><FormattedMessage id="the consumer" /></b>,
        to: <b><FormattedMessage id="the trader" /></b>
      }});

    if (answer !== true) return;

    try {
      await traderService.payedByConsumer(purchaseCode);
      notificationManager.success('trader.payment.success');
      loadPurchase();
      loadStates();
    } catch (ex) {
      if (/Purchase already payed/gi.test(ex.message))
        notificationManager.error('trader.payment.alreadyRegistered');
      else
        notificationManager.error(ex.message);
      console.error(ex.message);
    }
  }

  return (
    <div
      className={`pay-button ${purchase.consumerPaidTrader ? 'disabled' : ''}`}
      onClick={purchase.consumerPaidTrader ? () => { } : payedByConsumer}
      title={intl.formatMessage({ id: 'consumer-has-payed' })}>
      <i className="fas fa-money-bill-wave"></i>
    </div>);
});

export const RequestTrader = ({ purchase: { trader }, purchase, loadPurchase, loadStates }) => {
  const buttons = purchase.state >= STATE_AMOUNT_PAYED_TO_CONSUMER && purchase.state < STATE_AMOUNT_RECEIVED_FROM_CONSUMER
    ? <TraderPaidButton purchase={purchase} loadPurchase={loadPurchase} loadStates={loadStates} />
    : null;

  const content = (trader && purchase)
    ? <RequestTraderContent trader={trader} purchase={purchase} />
    : <LoadingState />;

  return (
    <Section title="section.trader" buttons={buttons}>
      {content}
    </Section>);
};
