import React from 'react';

export const VALID = 1;
export const INVALID = 0;
export const ATTENTION = -1;
export const BLANK = undefined;

export const ValidationBox = ({ title, valid, color, icon, footer }) => {
  let iconClassName = '';

  switch (valid) {
    default: break;

    case VALID: {
      iconClassName = 'valid far fa-check-circle';
      color = 'green';
      break;
    }

    case INVALID: {
      iconClassName = 'invalid far fa-times-circle';
      color = 'red';
      break;
    }

    case ATTENTION: {
      iconClassName = 'attention fas fa-exclamation';
      color = 'orange';
      break;
    }

    case BLANK: {
      iconClassName = 'far fa-circle';
      color = 'blank';
      break;
    }
  }

  let className = 'counter';
  if (color) className += ` counter-${color}`;

  return (
    <div className={className}>
      <div className='counter-body'>
        {icon ? <div className={`icon icon-{color}`}><i className={icon}></i></div> : null}
        <div className="title">{title}</div>
        <div className="number">
          <i className={iconClassName}></i>
        </div>
        <div className="spacer"></div>
        {footer ? <div className="footer">{footer}</div> : null}
      </div>
    </div>);
}

export const BlankBox = () => <ValidationBox valid={BLANK} footer="-" icon="fa" color="blank" />;
