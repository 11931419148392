import React, { createRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import './Section.scss';

export const SECTION_MODE_COLLAPSED = 'collapsed';
export const SECTION_MODE_EXPANDED = 'expanded';
export const SECTION_MODE_ZOOMED = 'zoomed';

const SECTION_BACKGROUND_MODE_INACTIVE = 'inactive';
const SECTION_BACKGROUND_MODE_HIDDEN = 'hidden';
const SECTION_BACKGROUND_MODE_VISIBLE = 'visible';


export const Section = ({ title, children, buttons, initialMode, canZoom }) => {
  const [mode, setMode] = useState(initialMode);
  const [backgroundMode, setBackgroundMode] = useState(SECTION_BACKGROUND_MODE_INACTIVE);
  const [hasBeenOpen, setHasBeenOpen] = useState(initialMode !== SECTION_MODE_COLLAPSED);
  const [placeHolderOffset, setPlaceHolderOffset] = useState(undefined);
  const [sectionOffset, setSectionOffset] = useState(undefined);

  const ref = createRef();
  const placeHolderStyle = {};
  const sectionStyle = {};

  const toggle = () => {
    if (!hasBeenOpen && (mode === SECTION_MODE_COLLAPSED))
      setHasBeenOpen(true);
    setMode(mode === SECTION_MODE_COLLAPSED ? SECTION_MODE_EXPANDED : SECTION_MODE_COLLAPSED);
  }

  const zoom = () => {
    if (!hasBeenOpen) setHasBeenOpen(true);

    const currentOffset = {
      left: ref.current.offsetLeft,
      top: ref.current.offsetTop,
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    };

    setPlaceHolderOffset(currentOffset);
    setSectionOffset(currentOffset);

    setMode(SECTION_MODE_ZOOMED);
    setBackgroundMode(SECTION_BACKGROUND_MODE_HIDDEN);
    setTimeout(() => setBackgroundMode(SECTION_BACKGROUND_MODE_VISIBLE));
  }

  const unzoom = () => {
    setSectionOffset(placeHolderOffset);

    setBackgroundMode(SECTION_BACKGROUND_MODE_HIDDEN);
    setTimeout(() => {
      setMode(SECTION_MODE_EXPANDED);
      setPlaceHolderOffset(undefined);
      setBackgroundMode(SECTION_BACKGROUND_MODE_INACTIVE);
    }, 250);
  }

  let sectionClassName;
  let toggleClassName;
  let zoomClassName;
  let backgroundClassName;

  switch (mode) {
    case SECTION_MODE_COLLAPSED:
      sectionClassName = 'section-collapsed';
      toggleClassName = 'far fa-angle-up';
      zoomClassName = 'fas fa-expand';
      break;

    case SECTION_MODE_ZOOMED:
      sectionClassName = 'section-zoomed';
      toggleClassName = 'far fa-angle-down';
      zoomClassName = 'fas fa-compress';
      break;

    default:
      sectionClassName = 'section-expanded';
      toggleClassName = 'far fa-angle-down';
      zoomClassName = 'fas fa-expand';
      break;
  }

  if (backgroundMode === SECTION_BACKGROUND_MODE_HIDDEN) {
    backgroundClassName = 'section-background-active';
  } else if (backgroundMode === SECTION_BACKGROUND_MODE_VISIBLE) {
    backgroundClassName = 'section-background-active section-background-visible';
  }

  if (placeHolderOffset) {
    placeHolderStyle.left = `${placeHolderOffset.left}px`;
    placeHolderStyle.top = `${placeHolderOffset.top}px`;
    placeHolderStyle.width = `${placeHolderOffset.width}px`;
    placeHolderStyle.height = `${placeHolderOffset.height}px`;
  }

  if (sectionOffset) {
    sectionStyle.left = `${sectionOffset.left}px`;
    sectionStyle.top = `${sectionOffset.top}px`;
    sectionStyle.width = `${sectionOffset.width}px`;
    sectionStyle.height = `${sectionOffset.height}px`;
    setTimeout(() => setSectionOffset(undefined), 250);
  }

  const content = (typeof (children) !== 'function' ? children : children({ isZoomed: mode === SECTION_MODE_ZOOMED }));

  return (
    <section title={title} className="section-placeholder" style={placeHolderStyle} ref={ref}>
      <div className={`section-background ${backgroundClassName}`}></div>
      <div className={`section ${sectionClassName}`} style={sectionStyle}>
        <div className="section-header header">
          <h2 className="section-title">
            <FormattedMessage id={title} defaultMessage={title} />
          </h2>
          <div className="section-toggle-buttons">
            {buttons}
            {canZoom && mode !== SECTION_MODE_COLLAPSED ? <i className={zoomClassName} onClick={mode !== SECTION_MODE_ZOOMED ? zoom : unzoom}></i> : null}
            <i className={toggleClassName} onClick={toggle}></i>
          </div>
        </div>
        <div className="section-body">
          {hasBeenOpen ? content : null}
        </div>
      </div>
    </section>
  );
}
