import React from 'react';
import { Row, Col } from 'reactstrap';
import { useServices } from '@cashnu/services';
import { Address, Dashboard, DataTable } from '../../components';

import "./ConsumersPage.scss";
import { withNavigate } from '../../legacy/withNavigate';

const Consumer = ({ consumer, onClick }) =>
(
  <Row onClick={onClick}>
    <Col>
      {consumer.initials} {consumer.middlename} {consumer.lastname}
    </Col>
    <Col>
      <Address {...consumer.address} />
    </Col>
    <Col>
      {consumer.phoneNumber}
    </Col>
    <Col>
      {consumer.email}
    </Col>
  </Row>
);

export const ConsumersPage = withNavigate(({ navigate }) => {
  const { consumerService } = useServices();

  const onTraderSelect = (consumer) => {
    navigate(`/consumer/${consumer.consumerId}`);
  };

  const headerColumns = [
    { title: 'naam' },
    { title: 'adres' },
    { title: 'telefoon' },
    { title: 'email' },
  ];

    return (
      <Dashboard className="traders-page">
        <DataTable
          className="consumers"
          headerColumns={headerColumns}
          loadData={() => consumerService.list()}
          selectable>
          {(consumer) => <Consumer
            key={consumer.consumerId}
            consumer={consumer}
            onClick={() => onTraderSelect(consumer)}
          />}          
        </DataTable>
      </Dashboard>
    );
});
